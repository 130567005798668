import { Injectable } from '@angular/core';
import { AjustesProvider } from '../services/ajustes';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment'


@Injectable()
export class aseguradorasService {
    public headers:any;
    public url:string;

    constructor(
        private _ajustes: AjustesProvider,
        private http: HttpClient,
      ) { 
        this._ajustes.cargarSessionStorage();
        this.url = environment.IP_CONF
        this.headers = { 
          'Content-Type': 'application/json', 
          'Authorization': 'Token '+ this._ajustes.sesionStorage.token
        }
        
      }
    


      public getAseguradoras() {
        return this.http.get(this.url + 'core/aseguradora', {
            headers: this.headers,
            withCredentials: true
        })
    }

    public getEmpresas() {
      return this.http.get(this.url + 'core/empresas', {
          headers: this.headers,
          withCredentials: true
      })
  }

    public patchUrl(url:string, payload:any) {
        return this.http.patch(url, payload,{
            headers: this.headers,
            withCredentials: true
        })
    }
    public createEmpresa(empresa:string) {
      return this.http.post(this.url + 'core/empresas/', {nombre: empresa},  {
          headers: this.headers,
          withCredentials: true
      })
  }
  public actualizarEmpresa(id:any, payload:any){
    return this.http.patch(this.url + 'core/empresas/' + id + '/', payload,{
      headers: this.headers,
      withCredentials: true
    })
  }
  

    

    
}
