import { Component } from '@angular/core';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { AjustesProvider } from '../services/ajustes';
import { ConexionService } from './conexion.service';
import { NgbHighlight, NgbModal, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { AbstractControl, FormGroup, FormsModule, ReactiveFormsModule, FormArray,FormControl } from '@angular/forms';
import { CommonModule, formatDate } from '@angular/common';
import { FeatherModule } from 'angular-feather';


@Component({
  selector: 'app-conexion',
  templateUrl: './conexion.component.html',
  styleUrls: ['./conexion.component.css'],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbPaginationModule,
    FeatherModule,
    NgbHighlight
  ],
  standalone: true,
  providers: [
    ConexionService
  ]
})

export class ConexionComponent {
  COBERTURAS_QUALITAS = {    
    1: "Daños Materiales", 
    2: "DM Sólo pérdida total", 
    3: "Robo Total", 
    4: "Responsabilidad Civil", 
    5: "Gastos Médicos", 
    6: "Muerte del Conductor X Accidente", 
    7: "Gastos Legales", 
    8: "Equipo Especial [descripción del equipo | suma asegurada]", 
    9: "Adaptaciones Daños Materiales [descripción de la adaptación | suma asegurada]", 
    10: "Adaptaciones Robo Total [descripción de la adaptación | suma asegurada]", 
    11: "Extensión de RC", 
    12: "Exención de Deducible Daños Materiales", 
    13: "RC Pasajero", 
    14: "Asistencia Vial", 
    17: "Gastos de Transporte/Gastos X Perdida de Uso X Pérdidas Totales", 
    22: "RC Legal Ocupantes", 
    26: "Cancelación de deducible por vuelco o colisión", 
    28: "Gastos X Perdida de Uso X Perdidas Parciales", 
    31: "Daños por la carga. Tipo Carga [A o B o C] | [Descrip de la Carga] | [Num Remolques]", 
    40: "Exención de Deducible Robo Total",
    34: "RC en el Extranjero",
    47: "RC Complementaria Personas"
  };
  tipoOpciones: string[] = ['Autos', 'Pickups', 'Camiones de carga'];
  tipoPaquetes: string[] = ['Amplia', 'RC', 'Limitada'];
  tipo: string = '';
 
  marcas:any = [];
  marcasAna:any = {};
  submarcas:any = [];
  tipos:any = [];
  modelos:any = [];
  versiones:any = [];
  marcaSeleccionada: string = '';
  submarcaSeleccionada: string = ''; 
  modeloSeleccionado: string = ''; 
  versionSeleccionada: string = ''; 
  versionHDI:any;
  chubbMarca: string = ''; 
  chubbSubmarca: string = ''; 
  anioSeleccionado: string = ''; 
  descripcionesChubb : any = [];
  descripcionSeleccionada : string ='';
  selectedCoberturas: any = {};
  paquetesUnicos: any = [];
  coberturasPorPaquete :any;
  chubbDescripcionT: string ='';
  chubbId:string = ''
  ptotal:any;
  loader:boolean = false;
  categoriaQualitas :string ='';
 

  coberturas:any = [];
  coberturasRespuesta: any = [];
  coberturasHDI: any = [];
  result:string = '';
  owner:string = 'Seleccione una opción';
  aseguradora:string = 'Seleccione una opción';
  nueva_empresa:string = '';
  paquete:string = '';
  empresas:any = [];
  aseguradoras:any = [];
  cobertura_seleccionada:any = {
    paquete: "",
    nombre: "",
    clave: "",
    suma_asegurada: 0,
    deducible: 0
  };
  

  constructor(
    private _router: Router,
    private _ajustes: AjustesProvider,
    private ConexionService : ConexionService,
    private modalService: NgbModal
  ){
    window.scrollTo(0, 0);
    this.ConexionService.getEmpresas().subscribe((response:any) =>{
      this.empresas = response;
      this.empresas.unshift({nombre:'Seleccione una opción'});
    });
    this.ConexionService.getAseguradoras().subscribe((response:any) =>{
      this.aseguradoras = response;
      this.aseguradoras.unshift('Seleccione una opción');
    });
  }
  generarAnios() {
    const currentYear = 2025;
    const startYear = 1985;
    for (let year = startYear; year <= currentYear; year++) {
      this.modelos.push(year);
    }
  }

  getCoberturas(owner: string) {
    this.ConexionService.getCoberturas(owner, this.aseguradora).subscribe(
      (response) => {
        this.coberturas = response;
        if (this.aseguradora === 'qualitas') {
          this.ConexionService.getMarcasQualitas().then((marcasResponse: any) => {
            this.marcas = marcasResponse;
            this.marcaSeleccionada = this.marcas[0]?.descripcion || '';
            this.loadSubmarcas(this.marcaSeleccionada);
          }).catch((error) => {
            console.error('Error al obtener marcas:', error);
            this.marcas = [];
          });
        }
        if (this.aseguradora === 'chubb'){
          this.coberturas = response;
          this.procesarCoberturas();
          const paquetesUnicos = new Set();
          const coberturasPorPaquete = {};
          this.coberturas.forEach((cobertura) => {
              const cobID = cobertura.cobID;
              const paquete = cobertura.paquete;
              paquetesUnicos.add(paquete);
              if (!coberturasPorPaquete[paquete]) {
                  coberturasPorPaquete[paquete] = []; 
              }
              coberturasPorPaquete[paquete].push(cobertura);
              this.selectedCoberturas[cobID] = cobID;
              this.selectedCoberturas[cobID + '_id'] = cobertura.id;
              this.selectedCoberturas[cobID + '_nombre'] = cobertura.nombre;
              this.selectedCoberturas[cobID + '_sumaAsegurada'] = cobertura.sumaAsegurada;
              const deducible = (cobertura.deduciblePorcentaje ?? cobertura.deducibleAdicional) * 100;
              this.selectedCoberturas[cobID + '_deducible'] = deducible;                
          });
          this.paquetesUnicos = Array.from(paquetesUnicos);
          this.selectedCoberturas['coberturasPorPaquete'] = coberturasPorPaquete;
          this.coberturasPorPaquete = coberturasPorPaquete
          this.ConexionService.getMarcasChubb(this.owner).then((marcasResponse: any) => {
            this.marcas = marcasResponse
            const marcaID = marcasResponse.id
            this.loadSubmarcas(this.marcaSeleccionada);
          }).catch((error) => {
            this.marcas = [];
          });
        }if (this.aseguradora==='gnp'){
          
            this.paquetesUnicos =[]
            this.coberturas = response
            this.coberturas.forEach(cobertura => {
              this.paquetesUnicos.push(cobertura.paquete)
            });
            if(this.tipo){
              this.ConexionService.getMarcasGNP(this.tipo).then((marcasResponse: any) => {
                this.marcas=marcasResponse
                
                this.loadSubmarcas(this.marcaSeleccionada)
              })
            }
        }if (this.aseguradora==='ps'){
          
          this.paquetesUnicos =[]
          this.coberturas = response
          this.coberturas.forEach(cobertura => {
            this.paquetesUnicos.push(cobertura.paquete)
          });
          if(this.tipo){
            this.loader =true
            this.ConexionService.getMarcasPS(this.owner,this.tipo).then((marcasResponse: any) => {
              this.loader=false
              this.marcas=marcasResponse
              this.generarAnios()
              console.log(this.modelos)
             
              console.log(this.marcas)
              
              // this.loadSubmarcas(this.marcaSeleccionada)
            })
          }
      }
        
        
        
        if (this.aseguradora === 'ana'){
          this.coberturas = response;
          this.procesarCoberturas();
          const paquetesUnicos = new Set();
          const coberturasPorPaquete = {};
          this.coberturas.forEach((cobertura) => {
              const cobID = cobertura.cobID;
              const paquete = cobertura.paquete;
              paquetesUnicos.add(paquete);
              if (!coberturasPorPaquete[paquete]) {
                  coberturasPorPaquete[paquete] = []; 
              }
              coberturasPorPaquete[paquete].push(cobertura);
              this.selectedCoberturas[cobID] = cobID;
              this.selectedCoberturas[cobID + '_id'] = cobertura.id;
              this.selectedCoberturas[cobID + '_nombre'] = cobertura.nombre;
              this.selectedCoberturas[cobID + '_sumaAsegurada'] = cobertura.sumaAsegurada;
              const deducible = cobertura.deducibleAdicional
              this.selectedCoberturas[cobID + '_deducible'] = deducible;                
          });
          this.paquetesUnicos = Array.from(paquetesUnicos);
          this.selectedCoberturas['coberturasPorPaquete'] = coberturasPorPaquete;
          this.coberturasPorPaquete = coberturasPorPaquete
          this.loadmodelosAna();
        }
      },
      (error) => {
        console.error('Error al obtener coberturas:', error);
        this.coberturas = [];
      }
    );
  }
  onTipoChange(option: string): void {
    
    if (this.aseguradora ==='gnp'){
    switch (option) {
      case 'Autos':
        this.tipo = 'AUT';
        break;
      case 'Pickups':
        this.tipo = 'CA1';
        break;
      case 'Camiones de carga':
        this.tipo = 'CA2';
        break;
      
      default:
        this.tipo = '';
    }
    this.getCoberturas(this.owner)
    
  } 
  if (this.aseguradora ==='ana'){
   
    switch (option) {
      case 'Autos':
        this.tipo = '100';
        break;
      case 'Pickups':
        this.tipo = '210';
        break;
      case 'Camiones de carga':
        this.tipo = '200';
        break;
      default:
        this.tipo = '';
      }
      // this.loadmarcas(this.modeloSeleccionado)
      
      
      
    }
    if (this.aseguradora ==='ps'){
   
      switch (option) {
        case 'Autos':
          this.tipo = '01';
          break;
        case 'Pickups':
          this.tipo = '09';
          break;
        
        default:
          this.tipo = '';
        }
      this.getCoberturas(this.owner)
        
        
        
      }
    if (this.aseguradora ==='hdi'){
   
      switch (option) {
        case 'Autos':
          this.tipo = '4579';
          break;
        case 'Pickups':
          this.tipo = '3829';
          break;
        
          
        default:
          this.tipo = '';
        }
        this.loadmarcas(this.modeloSeleccionado)
        
        
        
      }
    
    
  }
  onTipoPaquete(paquete: string): void {
    
    if (this.aseguradora ==='hdi'){
      
    switch (paquete) {
      case 'Amplia':
        this.paquete = 'Amplia';
        break;
      case 'Limitada':
        this.paquete = 'Limitada';
        break;
      case 'RC':
        this.paquete = 'RC';
        break;
      
      default:
        this.paquete = '';
      }

    }
  }


  procesarCoberturas() {
    this.coberturas = this.coberturas.map((cobertura: { data: any }) => {
      const dataXML = cobertura.data;
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(dataXML, 'text/xml');
  
      let cobID: string | null = null;
      let sumaAsegurada: string | null = null;
      let deducible: string | null = null;
      let deduciblePorcentaje: string | null = null;
      let deducibleAdicional: string | null = null;
      let tipoSuma: string | null = null;
      let prima: string | null = null;
      let noCobertura: string | null = null;
  
      if (this.aseguradora === 'qualitas') {
        sumaAsegurada = this.obtenerValorNodo(xmlDoc, 'SumaAsegurada');
        tipoSuma = this.obtenerValorNodo(xmlDoc, 'TipoSuma');
        deducible = this.obtenerValorNodo(xmlDoc, 'Deducible');
        prima = this.obtenerValorNodo(xmlDoc, 'Prima');
        noCobertura = xmlDoc.getElementsByTagName('Coberturas')[0]?.getAttribute('NoCobertura');
      } else if (this.aseguradora === 'chubb') {
        cobID = this.obtenerValorNodo(xmlDoc, 'COBID');
        sumaAsegurada = this.obtenerValorNodo(xmlDoc, 'SUMAASEG');
        deducibleAdicional = this.obtenerValorNodo(xmlDoc, 'DADIC');
        deduciblePorcentaje = this.obtenerValorNodo(xmlDoc, 'DPCT');
      }
  
      return {
        ...cobertura,
        cobID: cobID ? Number(cobID) : null,
        sumaAsegurada: sumaAsegurada ? Number(sumaAsegurada) : null,
        tipoSuma: tipoSuma ? Number(tipoSuma) : null,
        deducible: deducible ? Number(deducible) : null,
        deduciblePorcentaje: deduciblePorcentaje ? Number(deduciblePorcentaje) : null,
        deducibleAdicional: deducibleAdicional || null,
        prima: prima ? Number(prima) : null,
        noCobertura: noCobertura ? Number(noCobertura) : null
      };
    });
  }
  obtenerValorNodo(xmlDoc: Document, tagName: string): string | null {
    const node = xmlDoc.getElementsByTagName(tagName)[0];
    return node ? node.textContent : null;
  }
  
  changePaquete() {
    if (this.aseguradora ==='chubb'){
      this.selectedCoberturas = {}
      const paqueteSeleccionado = this.paquete; 
      const coberturasDelPaquete = this.coberturasPorPaquete[this.paquete];
      if (coberturasDelPaquete) {
          coberturasDelPaquete.forEach((cobertura) => {
              const cobID = cobertura.cobID;
              this.selectedCoberturas[cobID] = cobID;
              this.selectedCoberturas[cobID + '_id'] = cobertura.id;
              this.selectedCoberturas[cobID + '_nombre'] = cobertura.nombre;
              this.selectedCoberturas[cobID + '_sumaAsegurada'] = cobertura.sumaAsegurada;
              const deducible = (cobertura.deduciblePorcentaje ?? cobertura.deducibleAdicional) * 100;
              this.selectedCoberturas[cobID + '_deducible'] = deducible;
          });
      
    }}if (this.aseguradora ==='gnp'){
      this.selectedCoberturas = {}

    }
  }


  loadSubmarcas(marca: string) {
    if (this.aseguradora === 'qualitas'){
    this.ConexionService.getSubmarcasQualitas(marca).then((submarcasResponse: any) => {
      this.submarcas = submarcasResponse;
      this.categoriaQualitas = submarcasResponse.cat
      this.submarcaSeleccionada = this.submarcas[0]?.descripcion || '';
      this.loadModelos(this.marcaSeleccionada,this.submarcaSeleccionada)
    }).catch((error) => {
      console.error('Error al obtener submarcas:', error);
      this.submarcas = [];
    });
    }
  if(this.aseguradora =='chubb'){
    this.loader=true
    this.ConexionService.getSubmarcasChubb(this.owner, marca).then((submarcasResponse: any) => {
      this.submarcas = submarcasResponse;
      this.loader = false
      this.loadModelos(this.marcaSeleccionada,this.submarcaSeleccionada)
    }).catch((error) => {
      this.loader = false
      console.error('Error al obtener submarcas:', error);
      this.submarcas = [];
    });
    }
    if (this.aseguradora ==='gnp'){
      if (this.tipo){
        this.loader =true
        this.ConexionService.getSubmarcasGNP(marca,this.tipo).then((submarcasResponse: any) => {
        this.submarcas = submarcasResponse;
        this.submarcas.sort((a, b) => a.descr.localeCompare(b.descr));
        this.loader = false
        this.loadModelos(this.marcaSeleccionada,this.submarcaSeleccionada)
      }).catch((error) => {
        this.loader = false
        console.error('Error al obtener submarcas:', error);
        this.submarcas = [];
      });

      }
    }
    if(this.aseguradora =='ps'){
      if(this.marcaSeleccionada){
        this.generarAnios()
      this.loader=true
      this.ConexionService.getsubMarcasPS(this.owner,this.tipo, this.marcaSeleccionada).then((submarcasResponse: any) => {
        this.submarcas = submarcasResponse;
        this.loader = false
        this.loadModelos(this.marcaSeleccionada,this.submarcaSeleccionada)
        
      }).catch((error) => {
        this.loader = false
        console.error('Error al obtener submarcas:', error);
        this.submarcas = [];
      });
      }}
    
  }

  loadmarcas(modelo:string){
    if(this.aseguradora ==='ana'){
      if(this.tipo){
        this.loader=true
        this.ConexionService.getMarcasAna( this.tipo, modelo, this.owner).then((submarcasResponse: any) => {
          const submarcasRs = submarcasResponse.transacciones.marca;
          this.marcasAna = submarcasRs.map((submarca: any) => ({
            id: submarca['@id'],
            descripcion: submarca['#text']
          }));
          this.loader=false
          
          this.loadModelosAna2(this.marcaSeleccionada,modelo)
        });
      }
    }
    if(this.aseguradora ==='hdi'){
      if(this.tipo){
        this.loader=true
        this.ConexionService.getMarcasHDI( this.tipo).then((marcasResponse: any) => {
          this.marcas = marcasResponse
          this.marcas.sort((a, b) => a.descr.localeCompare(b.descr));
          this.loader=false
          this.marcaSeleccionada = this.marcas[0].id;
          console.log(this.marcaSeleccionada)
          this.loadModelosAna2(this.marcaSeleccionada,modelo)
        });
      }
    }


  }

  loadmodelosAna(){
    this.ConexionService.getModelosAna(this.owner).then((modelosResponse: any) => {
      this.modelos = modelosResponse.transacciones.modelo
      
      this.loader=false

      this.loadmarcas(this.modeloSeleccionado)
    }).catch((error) => {
      this.marcas = [];
      this.loader=false
    });
  }
  loadModelosAna2(marca:string, modelo:string){
    if(this.aseguradora ==='ana'){
      if(this.tipo){
        this.ConexionService.getSubMarcasAna( this.marcaSeleccionada, this.tipo, modelo, this.owner).then((submarcasResponse: any) => {
          const submarcasRs = submarcasResponse.transacciones.submarca;
          this.submarcas = submarcasRs.map((submarca: any) => ({
            id: submarca['@clave'],
            descripcion: submarca['#text']
          }));
          this.submarcaSeleccionada = this.submarcas[0].id;
          this.loadDescripcion(marca,this.submarcaSeleccionada,modelo)
        });
      }
    }if(this.aseguradora ==='hdi'){
      if(this.tipo){
        this.ConexionService.getSubMarcasHDI( this.marcaSeleccionada, this.tipo).then((submarcasResponse: any) => {
          this.submarcas =submarcasResponse
          this.submarcaSeleccionada = this.submarcas[0];
          this.loadModelos(this.marcaSeleccionada,this.submarcaSeleccionada)
        });
      }
    }
  }

  

  loadModelos(marca:string, submarca:string){
    if (this.aseguradora==='qualitas'){
    this.ConexionService.getModelosQualitas(marca,submarca).then((modelosResponse: any) => {
      this.modelos = modelosResponse;
      this.modeloSeleccionado = this.modelos[0]
      this.loadDescripcion(this.marcaSeleccionada,this.submarcaSeleccionada,this.modeloSeleccionado)
      }).catch((error:any)=>{
        this.modelos = [];
      })}
    if (this.aseguradora ==='chubb'){
      this.loader = true
      this.ConexionService.getModelosChubb(this.chubbMarca, this.chubbSubmarca).then((modelosResponse: any) => {
        this.modelos = modelosResponse;
        this.loader=false
        this.loadDescripcion(this.marcaSeleccionada,this.submarcaSeleccionada,this.modeloSeleccionado)
    }).catch((error:any)=>{
        this.loader=false
        console.error('Error al obtener modelos:', error);
        this.modelos = [];
      })
    }
    if(this.aseguradora ==='gnp'){
      if(this.tipo){
      this.loader = true
      this.ConexionService.getModelosGNP(marca,submarca,this.tipo).then((modelosResponse: any) => {
        this.modelos = modelosResponse;
        this.loader=false
        this.loadDescripcion(this.marcaSeleccionada,this.submarcaSeleccionada,this.modeloSeleccionado)
    }).catch((error:any)=>{
        this.loader=false
        console.error('Error al obtener modelos:', error);
        this.modelos = [];
      })

    }}if(this.aseguradora ==='ps'){
      this.generarAnios()
      if(this.tipo && this.modeloSeleccionado){
        this.versiones =[]
        
      this.loader = true
      this.ConexionService.getVehiculosPS(this.owner,this.submarcaSeleccionada,this.tipo, this.marcaSeleccionada, this.modeloSeleccionado).then((modelosResponse: any) => {
        if  (modelosResponse.errMsg){
          Swal.fire('ERROR', 'El servicio de ANA regresó el siguiente error: \n ' + '\n'+modelosResponse.errMsg, 'warning');

        }
        
        this.versiones = modelosResponse;
        this.loader=false
        
    }).catch((error:any)=>{
        this.loader=false
        console.error('Error al obtener modelos:', error);
        this.modelos = [];
      })

    }}
    
    
    if(this.aseguradora ==='hdi'){
      if(this.tipo){
      this.loader = true
      this.ConexionService.getModelosHDI(marca,submarca,this.tipo).then((modelosResponse: any) => {
        this.modelos = modelosResponse;
        console.log(this.modelos)
        this.modeloSeleccionado = this.modelos[0]
        this.loader=false
        this.loadDescripcion(this.marcaSeleccionada,this.submarcaSeleccionada,this.modeloSeleccionado)
    }).catch((error:any)=>{
        this.loader=false
        console.error('Error al obtener modelos:', error);
        this.modelos = [];
      })

    }}
    
  }


  loadDescripcion(marca:string,submarca:string,modelo:string){
    if (this.aseguradora==='qualitas'){
    this.ConexionService.getDescripcionesQualitas(marca,submarca,modelo).then((descripcionResponse: any) => {
        this.versiones = descripcionResponse;
        this.versionSeleccionada = this.versiones[0]?.clave || ''
        this.categoriaQualitas = descripcionResponse[0]?.cat
    }).catch((error:any)=>{
      console.error('Error al obtener modelos:', error);
        this.versiones = [];
    })}
    if (this.aseguradora==='chubb'){
      this.loader =true
      this.ConexionService.getDescripcionesChubb(marca,submarca,modelo, this.owner).then((descripcionResponse: any) => {
          this.versiones = descripcionResponse;
          this.loader =false
      }).catch((error:any)=>{
          this.loader = false
          console.error('Error al obtener modelos:', error);
          this.versiones = [];
      })
    }
    if (this.aseguradora ==='gnp'){
      this.loader =true
      this.ConexionService.getDescripcionesGNP(marca,submarca,modelo ).then((descripcionResponse: any) => {
          this.versiones = descripcionResponse;
          this.loader =false
      }).catch((error:any)=>{
          this.loader = false
          console.error('Error al obtener modelos:', error);
          this.versiones = [];
      })
    }

    if (this.aseguradora==='ana'){
      this.loader =true
      this.ConexionService.getVehiculosAna(submarca,marca,this.tipo,modelo, this.owner).then((descripcionResponse: any) => {
          this.versiones = descripcionResponse.transacciones.vehiculo
          const versionesRs = descripcionResponse.transacciones.vehiculo;
          this.versiones = versionesRs.map((submarca: any) => ({
            id: submarca['@clave'],
            descripcion: submarca['#text']
          }));
          this.versionSeleccionada = this.versiones[0].id;




          this.loader =false
      }).catch((error:any)=>{
          this.loader = false
          console.error('Error al obtener modelos:', error);
          this.versiones = [];
      })
    }
    if(this.aseguradora==='hdi'){
      this.loader =true
      this.ConexionService.getVehiculosHDI(this.modeloSeleccionado, this.marcaSeleccionada, this.submarcaSeleccionada, this.tipo ).then((descripcionResponse: any) => {
        this.versiones =descripcionResponse
        this.versionSeleccionada = this.versiones[0].id;        
        
        this.loader=false
      })

    }
    if(this.aseguradora==='ps'){
      this.loader =true
      this.ConexionService.getVehiculosHDI(this.modeloSeleccionado, this.marcaSeleccionada, this.submarcaSeleccionada, this.tipo ).then((descripcionResponse: any) => {
        this.versiones =descripcionResponse
        this.versionSeleccionada = this.versiones[0].id;        
        
        this.loader=false
      })

    }
  }

  onMarcaSeleccionada(event: any) {
    console.log(event.target.value)
    if (this.aseguradora==='chubb'){
      const selectElement = event.target as HTMLSelectElement;
      if (!selectElement) {
        console.error('Elemento select no encontrado');
        return;
      }
      const id = selectElement.value;
      this.chubbMarca = selectElement.selectedOptions[0]?.getAttribute('data-descripcion');
    }
    this.marcaSeleccionada = event.target.value;
    this.loadSubmarcas(this.marcaSeleccionada);
    if (this.aseguradora ==='gnp'){
      this.marcaSeleccionada = event.target.value;
    }
    if (this.aseguradora ==='ana'){
      this.marcaSeleccionada = event.target.value;
      this.loadModelosAna2(this.marcaSeleccionada, this.modeloSeleccionado)
    }
    if (this.aseguradora ==='hdi'){
      this.marcaSeleccionada = event.target.value;
      this.loadModelosAna2( this.modeloSeleccionado, this.tipo)
    }
    if (this.aseguradora ==='ps'){
      this.marcaSeleccionada = event.target.value;
      this.loadSubmarcas(this.marcaSeleccionada)
    }
    

  }

  onSubmarcaSeleccionada(event: any) {
    if (this.aseguradora==='chubb'){
      const selectElement = event.target as HTMLSelectElement;
      const id = selectElement.value;
      this.chubbSubmarca = selectElement.selectedOptions[0]?.getAttribute('data-descripcion');
      this.submarcaSeleccionada = event.target.value;
      this.loadModelos(this.marcaSeleccionada,this.submarcaSeleccionada)
  
    }if (this.aseguradora==='ana'){
      this.loadDescripcion(this.marcaSeleccionada,this.submarcaSeleccionada,this.modeloSeleccionado)

    }if (this.aseguradora==='hdi'){
      this.loadModelos(this.marcaSeleccionada,this.submarcaSeleccionada)

    }if (this.aseguradora==='gnp'){
      this.loadModelos(this.marcaSeleccionada,this.submarcaSeleccionada)

    }
    
  }  

  
  onModeloSeleccionado(event: any) {
    if (this.aseguradora ==='chubb'){
      const selectElement = event.target as HTMLSelectElement;
      this.modeloSeleccionado = event.target.value;
    this.loadDescripcion(this.marcaSeleccionada,this.submarcaSeleccionada,this.modeloSeleccionado)

    }
    if (this.aseguradora ==='ana'){
      const selectElement = event.target as HTMLSelectElement;
      this.modeloSeleccionado = event.target.value;
      this.loadmarcas(this.modeloSeleccionado)
    }
    if (this.aseguradora ==='hdi'){
      const selectElement = event.target as HTMLSelectElement;
      this.modeloSeleccionado = event.target.value;
      this.loadDescripcion(this.marcaSeleccionada,this.submarcaSeleccionada, this.modeloSeleccionado)
    }
    if (this.aseguradora ==='gnp'){
      const selectElement = event.target as HTMLSelectElement;
      this.modeloSeleccionado = event.target.value;
      this.loadDescripcion(this.marcaSeleccionada,this.submarcaSeleccionada,this.modeloSeleccionado)
    }
    if (this.aseguradora ==='ps'){
      const selectElement = event.target as HTMLSelectElement;
      this.modeloSeleccionado = event.target.value;
      this.loadModelos(this.marcaSeleccionada,this.submarcaSeleccionada)
    }
    
  }

  onDescripcionSeleccionado(event: any) {
    if (this.aseguradora ==='chubb'){
      this.loader =true
      const id = event.target as HTMLSelectElement;
      this.ConexionService.getChubbFinal(this.owner, id.value, this.modeloSeleccionado).then((descripcionResponse: any) => {
        this.loader=false
          this.descripcionesChubb = descripcionResponse;
      }).catch((error:any)=>{
        this.loader=false
        console.error('Error al obtener modelos:', error);
          this.descripcionesChubb = [];
      })
    }
    
    this.versionSeleccionada = event.target.value;
    console.log(this.versionSeleccionada)
    
    
    
  }


  onChubb(event:any){
    const cveveh = event.target.value;
    if (this.aseguradora==='chubb'){
      const selectElement = event.target as HTMLSelectElement;
      this.chubbId = selectElement.value;
      this.chubbDescripcionT = selectElement.selectedOptions[0]?.getAttribute('data-descripcion');
    }
    this.submarcaSeleccionada = event.target.value;
  }

  changeEmpresa(){
    if(this.aseguradora !=='hdi' && this.owner){
      this.getCoberturas(this.owner);
      this.cobertura_seleccionada={};
    }
  }

  changeAseguradora(){
    if (this.owner && this.aseguradora){
      this.getCoberturas(this.owner);
      this.cobertura_seleccionada={};
    }
  }

  closeBtnClick() {
    this.modalService.dismissAll();
  }

  deleteCobertura(cobertura:any, index:number){
    Swal.fire({
      title: '¿Está seguro?',
      text: "Esta operación no podrá deshacerse",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, borrar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        this.ConexionService.deleteCoberturas(cobertura.id, this.aseguradora).subscribe(() =>{
          this.coberturas.splice(index, 1);
        });
      }
    });
  }


  guardarCobertura(){
    if(this.cobertura_seleccionada && this.cobertura_seleccionada.id){
      this.ConexionService.updateCobertura(this.cobertura_seleccionada, this.aseguradora).subscribe((response:any) =>{
        this.closeBtnClick();
      });
    } else {
      this.cobertura_seleccionada['owner'] = this.owner;
      this.ConexionService.createCoberturas(this.cobertura_seleccionada, this.aseguradora).subscribe((response:any) =>{
        this.coberturas.unshift(response)
        this.closeBtnClick();
      });
    }
  }


  guardarEmpresa(){
    this.ConexionService.createEmpresa(this.nueva_empresa).subscribe((response:any) =>{
      this.empresas.unshift(response);
      this.nueva_empresa = '';
      this.closeBtnClick();
    });
  }


  modalCobertura(modalCobertura:any, cobertura:any){
    this.cobertura_seleccionada = cobertura;
    this.modalService.open(modalCobertura, {
      centered: true,
      backdrop: 'static',
      size:'lg'
    });
  }

  modalEmpresa(modalEmpresa:any){
    this.modalService.open(modalEmpresa, {
      centered: true,
      backdrop: 'static',
      size:'md'
    });
  }
  calcularSumaPrimas(): number {
    return this.coberturasRespuesta.reduce((total: number, cobertura: { Prima: string; }) => {
      const prima = parseFloat(cobertura.Prima) || 0; 
      return total + prima;
    }, 0);
  }
  

  
  probarConexion() {
    if (this.aseguradora=='qualitas') {
      const paquete=this.paquete
      this.coberturasRespuesta = [];
      this.ConexionService.testConn(this.owner, this.aseguradora, paquete, this.versionSeleccionada, this.modeloSeleccionado,this.categoriaQualitas).subscribe(
        (response: any) => {
          try {
            let responseText = response['response_xml'];
            const cleanedResponse = responseText
              .replace(/^b'/, '') 
              .replace(/\\n/g, '') 
              .replace(/\\"/g, '"') 
              .replace(/&lt;/g, '<') 
              .replace(/&gt;/g, '>') 
              .replace(/&quot;/g, '"') 
              .slice(0, -1); 
            const coberturasRegex = /<Coberturas.*?<\/Coberturas>/g;
            const coberturasArray = cleanedResponse.match(coberturasRegex);
            const resultados = coberturasArray.map(cobertura => {
              const parser = new DOMParser();
              const xmlDoc = parser.parseFromString(cobertura, "text/xml");
              const noCobertura = xmlDoc.documentElement.getAttribute("NoCobertura");
              const sumaAsegurada = xmlDoc.getElementsByTagName("SumaAsegurada")[0]?.textContent || 'N/A';
              const tipoSuma = xmlDoc.getElementsByTagName("TipoSuma")[0]?.textContent || 'N/A';
              const deducible = xmlDoc.getElementsByTagName("Deducible")[0]?.textContent || 'N/A';
              const prima = xmlDoc.getElementsByTagName("Prima")[0]?.textContent || 'N/A';
  
              return {
                NoCobertura: noCobertura,
                SumaAsegurada: sumaAsegurada,
                TipoSuma: tipoSuma,
                Deducible: deducible,
                Prima: prima
              };
            });
            this.coberturasRespuesta = resultados
          } catch (error) {
            console.error('Error al procesar la respuesta:', error);
          }
        }
      );
    }if (this.aseguradora=='chubb') {
      // this.loader=true
      this.ConexionService.testConnChubb(this.owner, this.aseguradora, this.paquete, this.chubbId, this.modeloSeleccionado, this.chubbDescripcionT).subscribe(
        (response: any) => {
          // this.loader=false
          this.ptotal = response.COT.PTOTAL
          this.coberturasRespuesta = response.COT.INCISOS.INCISO.COBS.COB
          this.coberturasRespuesta = this.coberturasRespuesta.filter(cobertura => cobertura.PTOTAL !== "0");
        })
    }
    if (this.aseguradora=='ana') {
      this.loader=true
      this.ConexionService.testConnGNP(this.owner, this.aseguradora, this.paquete, this.modeloSeleccionado, this.versionSeleccionada,this.tipo).subscribe((response:any) => {
        this.loader=false
        this.coberturasRespuesta = response.transacciones.transaccion.vehiculo.cobertura
        this.ptotal = parseInt(response.transacciones.transaccion.prima['@primatotal'])
        if (response.error){
          this.coberturasRespuesta = []
          Swal.fire('ERROR', 'El servicio de ANA regresó el siguiente error: \n ' + '\n'+response.error, 'warning');

        }
      }, error => {
        this.result = error;
        this.loader=false
      })
    }if (this.aseguradora=='hdi') {
      this.loader=true
      
      this.ConexionService.testConnGNP(this.owner, this.aseguradora, this.paquete, this.modeloSeleccionado, this.versionSeleccionada,this.tipo).subscribe((response:any) => {
        this.coberturasRespuesta = response;
        this.loader=false
      const coberturasCombinadas = [
        ...this.coberturasRespuesta.cotizacion.CoberturasObligatorias.map((cobertura) => ({
          ...cobertura,
          tipoCobertura: 'CoberturasObligatorias'
        })),
        ...this.coberturasRespuesta.cotizacion.CoberturasObligatoriasOpcionales.map((cobertura) => ({
          ...cobertura,
          tipoCobertura: 'CoberturasObligatoriasOpcionales'
        })),
        ...this.coberturasRespuesta.cotizacion.CoberturasOpcionales
          .filter((cobertura) => cobertura.Calculada === true)
          .map((cobertura) => ({
            ...cobertura,
            tipoCobertura: 'CoberturasOpcionales'
          }))
      ];
        this.coberturasHDI = coberturasCombinadas
        this.ptotal = coberturasCombinadas.reduce((total, cobertura) => {
          const primaNeta = parseInt(cobertura.PrimaNeta, 10) || 0; 
          return total + primaNeta;
      }, 0);
        
      }, error => {
        this.result = error;
        this.loader=false
      })
    }
    if(this.aseguradora==='ps'){
      this.paquete
      this.ConexionService.testConnGNP(this.owner, this.aseguradora, this.paquete, this.modeloSeleccionado, this.versionSeleccionada, this.tipo).subscribe((response:any) => {
        console.log(response)
        this.coberturasRespuesta =response.coberturas
        console.log(this.coberturasRespuesta)
        this.ptotal = response.prima_total
      })



    }
    if (this.aseguradora=='gnp') {
      
      this.ConexionService.testConnGNP(this.owner, this.aseguradora, this.paquete, this.modeloSeleccionado, this.versionSeleccionada,this.tipo).subscribe((response:any) => {
        this.result = response;
        if (response.ERROR){
          this.marcas=[]
          this.modelos=[]
          this.submarcas =[]
          this.versiones =[]
          Swal.fire('ERROR', 'El servicio de GNP regresó el siguiente error: \n ' + '\n'+response.ERROR.DESCRIPCION, 'warning');
        }
        this.coberturasRespuesta = response.COTIZACION.PAQUETES.PAQUETE.COBERTURAS.COBERTURA
        this.ptotal = response.COTIZACION.PAQUETES.PAQUETE.TOTALES.TOTAL_PRIMA.CONCEPTO_ECONOMICO[9].MONTO
        console.log(response['response_xml'])
        console.log(response.COTIZACION)
      }, error => {
        this.result = error;
      })
    }
    
  }
    
  
  getResultadoCobertura(key: string, tipo: string) {
    const resultado = this.coberturasRespuesta.find(item => item.NoCobertura === key);
    return resultado ? resultado[tipo] : 'N/A'; 
  }

  formatResultado(resultado: any): string {
    return resultado === 'N/A' ? 0 : resultado;
  }
  
  formatDeducible(deducible: string): string {
    if (!deducible || deducible === 'N/A') {
      return '-';
    }
    const deducibleNum = deducible.slice(-5); 
    const porcentaje = parseInt(deducibleNum, 10);
    if (porcentaje === 0) {
      return '0%'; 
    }
    return `${porcentaje} %`; 
  }
}
