export const environment = {
  production: false,
  IP_CONF: 'https://apigatewaymc.multicotizador.com/',
  API_MAIL: 'https://mail.mbxservicios.com/mails/',
  SOCKET_ENDPOINT_NOTIFICACIONES: 'wss://apigatewaymc.multicotizador.com/ws/notificaciones/',
  URL_QUALITAS: 'https://qualitas-api.multicotizador.com/',
  URL_CHUBB: 'https://avantthya-api.multicotizador.com/',
  URL_ANA: 'https://anaseguros-api.multicotizador.com/',
  URL_HDI: 'https://hdi-api.multicotizador.com/',
  URL_GNP: 'https://gnp-api.multicotizador.com/',
  URL_PS: 'https://ps-api.multicotizador.com/',
  URL_MOMENTO: 'https://tumomento-ms.multicotizador.com/',

  //  IP_CONF: 'http://127.0.0.1:8000/',
  // SOCKET_ENDPOINT_NOTIFICACIONES: 'ws://127.0.0.1:8000/ws/notificaciones/',
  // API_MAIL: 'https://mail.mbxservicios.com/mails/',
  // URL_QUALITAS: 'http://127.0.0.1:5000/',
  // URL_CHUBB: 'http://127.0.0.1:5002/',
  // URL_PS: 'http://127.0.0.1:5009/',
  // URL_GNP: 'http://127.0.0.1:5001/',
  //  URL_ANA: 'http://127.0.0.1:5003/',
  //  URL_HDI: 'http://127.0.0.1:5007/',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
