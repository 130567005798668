import { Injectable } from '@angular/core';
import { Formato, Carpeta } from './formato';
import { AjustesProvider } from '../services/ajustes';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment'
import { Observable } from 'rxjs';
import { Form } from '@angular/forms';


@Injectable()
export class FormatoService {
    public headers:any;
    public url:string;
    public hdi:string;

    constructor(
        private _ajustes: AjustesProvider,
        private http: HttpClient,
      ) { 
        this._ajustes.cargarSessionStorage();
        this.url = environment.IP_CONF
        this.hdi =environment.URL_HDI
        this.headers = { 
          'Content-Type': 'application/json', 
          'Authorization': 'Token '+ this._ajustes.sesionStorage.token
        }
      }
    

    public formato: Formato[] = [];

    public getCoberturas(owner:string, aseguradora:string) {
        return this.http.get(this.url + 'cotizar/coberturas/'+aseguradora+'?owner='+owner, {
            headers: this.headers,
            withCredentials: true
        })
    }
    public getCoberturasHDI(owner:string, paquete:string) {
        return this.http.get(this.hdi + 'coberturas?owner='+owner+'&paquete='+paquete)
    }


    public getEmpresas() {
        return this.http.get(this.url + 'core/empresas', {
            headers: this.headers,
            withCredentials: true
        })
    }


    public getAseguradoras() {
        return this.http.get(this.url + 'core/aseguradoras', {
            headers: this.headers,
            withCredentials: true
        })
    }

    public deleteCoberturas(id:number, aseguradora:string) {
        return this.http.delete(this.url + 'cotizar/coberturas/'+aseguradora+'?id='+id, {
            headers: this.headers,
            withCredentials: true
        })
    }
    public updateCobertura(cobertura:any, aseguradora: string) { 
        return this.http.patch(this.url + 'cotizar/coberturas/'+aseguradora+'', cobertura, {
            headers: this.headers,
            withCredentials: true
        })
    }
    public createCoberturas(cobertura: any, aseguradora: string) {
        return this.http.post(this.url + 'cotizar/coberturas/'+aseguradora+'', cobertura,  {
            headers: this.headers,
            withCredentials: true
        })
    }
    public createEmpresa(empresa:string) {
        return this.http.post(this.url + 'core/empresas/', {nombre: empresa, aseguradoras: [1]},  {
            headers: this.headers,
            withCredentials: true
        })
    }
    public testConn(owner:string, aseguradora:string, paquete:string) {
        return this.http.post(this.url + 'cotizar/probar-conexion/'+aseguradora+'?owner='+owner,{paquete, empresa:owner}, {
            headers: this.headers,
            withCredentials: true
        })
    }
}
