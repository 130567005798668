<div *ngIf="loader" class="container-load">
  <img class="image-load" src="../../../assets/loader.gif" alt="Cargando...">
</div>
<div class="row">
    <div class="card card-body">
      <div class="col-12 ms-3">
        <div class="row">
          <div class="col-12 col-sm-4 mt-3">
            <label class="mb-3">Aseguradora</label>
            <select [(ngModel)]="aseguradora" (change)="changeAseguradora()" class="form-control">
              <option *ngFor="let aseguradora of aseguradoras" [value]="aseguradora">{{ aseguradora }}</option>
            </select>
  
            <label class="mb-3 mt-3">Negocio</label>
            <select [(ngModel)]="owner" (change)="changeEmpresa()" class="form-control">
              <option *ngFor="let empresa of empresas" [value]="empresa.nombre">{{ empresa.nombre }}</option>
            </select>
            <div *ngIf="aseguradora === 'hdi'" class="mt-4">
              <label class="d-block mb-2">Selecciona un paquete</label>
              <select class="form-control" [(ngModel)]="paquete" (change)="onTipoPaquete(paquete)">
                <option value="" disabled selected>Seleccione un paquete</option>
                <option *ngFor="let paquete of tipoPaquetes" [value]="paquete">
                  {{ paquete }}
                </option>
              </select>
            </div>
            
            
          
         
            <label *ngIf="aseguradora === 'chubb' || aseguradora ==='gnp' || aseguradora ==='ana' || aseguradora ==='ps'    " class="mb-3">Paquete</label>
            <select *ngIf="aseguradora === 'chubb' || aseguradora ==='gnp' || aseguradora ==='ana' || aseguradora ==='ps'  "  [(ngModel)]="paquete" (change)="changePaquete()" class="form-control col-6">
                <option value="" disabled selected>Seleccione un paquete</option>
                <option *ngFor="let paquet of paquetesUnicos" [value]="paquet">{{paquet}}</option>
            </select>
           </div>
           <div *ngIf="aseguradora =='gnp' || aseguradora =='ana' || aseguradora ==='hdi' || aseguradora ==='ps'  " class="mt-4">
            <label *ngFor="let option of tipoOpciones" class="d-block mb-2">
              <input
                  type="radio"
                  name="tipo"
                  [value]="option"
                  (change)="onTipoChange(option)"
                />
                <span class="ml-2">{{ option }}</span>
            </label>
          </div>
          </div>
  
          
        <div class="row">
            <div class="col-12 col-sm-4 mt-4" *ngIf="aseguradora ==='ana'">
              <label class="mb-3">Año</label>
              <select [(ngModel)]="modeloSeleccionado" (change)="onModeloSeleccionado($event)" class="form-control">
                <option value="" disabled selected>Seleccione año</option>

                <option *ngFor="let modelo of modelos" [value]="modelo">{{ modelo }}</option>
              </select>
            </div>
          
            <div class="col-12 col-sm-4 mt-4" *ngIf="aseguradora === 'qualitas'" >
                <label class="mb-3">Marca del vehículo</label>
                <select [(ngModel)]="marcaSeleccionada" (change)="onMarcaSeleccionada($event)" class="form-control">
                  
                  <option  *ngFor="let marca of marcas" [value]="marca.descripcion">{{ marca.descripcion }}</option>
                </select>
              </div>
              <div class="col-12 col-sm-4 mt-4" *ngIf="aseguradora === 'ana'" >
                <label class="mb-3">Marca del vehículo</label>
                <select [(ngModel)]="marcaSeleccionada" (change)="onMarcaSeleccionada($event)" class="form-control">
                  <option value="" disabled selected>Seleccione una marca</option>

                  <option  *ngFor="let marca of marcasAna" [value]="marca.id">{{ marca.descripcion }}</option>
                </select>
              </div>
              <div class="col-12 col-sm-4 mt-4" *ngIf="aseguradora === 'ps'" >
                <label class="mb-3">Marca del vehículo</label>
                <select [(ngModel)]="marcaSeleccionada" (change)="onMarcaSeleccionada($event)" class="form-control">
                  <option value="" disabled selected>Seleccione una marca</option>

                  <option  *ngFor="let marca of marcas" [value]="marca.idMarca">{{ marca.marca }}</option>
                </select>
              </div>
              <div class="col-12 col-sm-4 mt-4" *ngIf="aseguradora === 'hdi'" >
                <label class="mb-3">Marca del vehículo</label>
                <select [(ngModel)]="marcaSeleccionada" (change)="onMarcaSeleccionada($event)" class="form-control">
                  <option value="" disabled selected>Seleccione una marca</option>

                  <option  *ngFor="let marca of marcas" [value]="marca.id">{{ marca.descr }}</option>
                </select>
              </div>
              

              
              <div class="col-12 col-sm-4 mt-4" *ngIf="aseguradora === 'chubb' " >
                <label class="mb-3">Marca del vehículo</label>
                <select [(ngModel)]="marcaSeleccionada" (change)="onMarcaSeleccionada($event)" class="form-control">
                  <option value="" disabled selected>Seleccione una marca</option>
                  <option  *ngFor="let marca of marcas" [value]="marca.id" [attr.data-descripcion]="marca.descripcion">{{ marca.descripcion }}</option>
                </select>
              </div>
              <div class="col-12 col-sm-4 mt-4" *ngIf="aseguradora === 'gnp' " >
                <label class="mb-3">Marca del vehículo</label>
                <select [(ngModel)]="marcaSeleccionada" (change)="onMarcaSeleccionada($event)" class="form-control">
                  <option value="" disabled selected>Seleccione una marca</option>
                  <option  *ngFor="let marca of marcas" [value]="marca.id">{{ marca.descr }}</option>
                </select>
              </div>
              
      
              <div class="col-12 col-sm-4 mt-4" *ngIf="aseguradora === 'qualitas'  " >
                <label class="mb-3">Submarca del vehículo</label>
                <select [(ngModel)]="submarcaSeleccionada" (change)="onSubmarcaSeleccionada($event)" class="form-control">
                  <option *ngFor="let submarca of submarcas" [value]="submarca.descripcion">{{ submarca.descripcion }}</option>
                </select>
              </div>
              <div class="col-12 col-sm-4 mt-4" *ngIf="aseguradora === 'ana'  " >
                <label class="mb-3">Submarca del vehículo</label>
                <select [(ngModel)]="submarcaSeleccionada" (change)="onSubmarcaSeleccionada($event)" class="form-control">
                  <option *ngFor="let submarca of submarcas" [value]="submarca.id">{{ submarca.descripcion }}</option>
                </select>
              </div>
              <div class="col-12 col-sm-4 mt-4" *ngIf="aseguradora === 'ps'  " >
                <label class="mb-3">Submarca del vehículo</label>
                <select [(ngModel)]="submarcaSeleccionada" (change)="onSubmarcaSeleccionada($event)" class="form-control">
                  <option value="" disabled selected>Seleccione una submarca</option>
                  <option *ngFor="let submarca of submarcas" [value]="submarca.idSubMarca">{{ submarca.subMarca }}</option>
                </select>
              </div>
              <div class="col-12 col-sm-4 mt-4" *ngIf="aseguradora === 'hdi'  " >
                <label class="mb-3">Submarca del vehículo</label>
                <select [(ngModel)]="submarcaSeleccionada" (change)="onSubmarcaSeleccionada($event)" class="form-control">
                  <option *ngFor="let submarca of submarcas" [value]="submarca">{{ submarca }}</option>
                </select>
              </div>
             
              <div class="col-12 col-sm-4 mt-4" *ngIf="aseguradora === 'chubb'">
                <label class="mb-3">Submarca </label>
                <select 
                  [(ngModel)]="chubbSubmarca" 
                  (change)="onSubmarcaSeleccionada($event)" 
                  class="form-control">
                  <option value="" disabled selected>Seleccione una submarca</option>
                  <option *ngFor="let submarca of submarcas" 
                          [value]="submarca.id" 
                          [attr.data-descripcion]="submarca.descripcion">
                    {{ submarca.descripcion }}
                  </option>
                </select>
              </div>
              <div class="col-12 col-sm-4 mt-4" *ngIf="aseguradora === 'gnp'  " >
                <label class="mb-3">Submarca del vehículo</label>
                <select [(ngModel)]="submarcaSeleccionada" (change)="onSubmarcaSeleccionada($event)" class="form-control">
                  <option value="" disabled selected>Seleccione submarca</option>
                  <option *ngFor="let submarca of submarcas" [value]="submarca.id">{{ submarca.descr }}</option>
                </select>
              </div>





              <div class="col-12 col-sm-4 mt-4" *ngIf="aseguradora === 'chubb' " >
                <label class="mb-3">Año</label>
                <select [(ngModel)]="modeloSeleccionado" (change)="onModeloSeleccionado($event)" class="form-control">
                  <option value="" disabled selected>Seleccione un año</option>
                  <option *ngFor="let modelo of modelos" [value]="modelo">{{ modelo }}</option>
                </select>
              </div>
              <div class="col-12 col-sm-4 mt-4" *ngIf="aseguradora === 'gnp' " >
                <label class="mb-3">Año</label>
                <select [(ngModel)]="modeloSeleccionado" (change)="onModeloSeleccionado($event)" class="form-control">
                  <option value="" disabled selected>Seleccione un año</option>
                  <option *ngFor="let modelo of modelos" [value]="modelo">{{ modelo }}</option>
                </select>
              </div>
              <div class="col-12 col-sm-4 mt-4" *ngIf="aseguradora === 'ps'">
                <label class="mb-3">Año</label>
                <select [(ngModel)]="modeloSeleccionado" (change)="onModeloSeleccionado($event)" class="form-control">
                  <option value="" disabled selected>Seleccione un año</option>
                  <option *ngFor="let modelo of modelos" [value]="modelo">{{ modelo}}</option>
                </select>
              </div>
              
              <div class="col-12 col-sm-4 mt-4" *ngIf="aseguradora === 'hdi' " >
                <label class="mb-3">Año</label>
                <select [(ngModel)]="modeloSeleccionado" (change)="onModeloSeleccionado($event)" class="form-control">
                  <option value="" disabled selected>Seleccione un año</option>
                  <option *ngFor="let modelo of modelos" [value]="modelo">{{ modelo }}</option>
                </select>
              </div>
              <div class="col-12 col-sm-4 mt-4" *ngIf="aseguradora === 'qualitas'  " >
                <label class="mb-3">Version</label>
                <select [(ngModel)]="versionSeleccionada" (change)="onDescripcionSeleccionado($event)" class="form-control">
                  <option *ngFor="let version of versiones" [value]="version.clave">{{ version.descripcion }}</option>
                </select>
            </div>
            <div class="col-12 col-sm-4 mt-4" *ngIf="aseguradora === 'ana'  " >
              <label class="mb-3">Version</label>
              <select [(ngModel)]="versionSeleccionada" (change)="onDescripcionSeleccionado($event)" class="form-control">
                <option *ngFor="let version of versiones" [value]="version.id">{{ version.descripcion }}</option>
              </select>
          </div>
          <div class="col-12 col-sm-4 mt-4" *ngIf="aseguradora === 'ps'  " >
            <label class="mb-3">Version</label>
            <select [(ngModel)]="versionSeleccionada" (change)="onDescripcionSeleccionado($event)" class="form-control">
              <option value="" disabled selected>Seleccione vehiculo</option>
              <option *ngFor="let version of versiones" [value]="version.clave">{{ version.version }}</option>
            </select>
          </div>
          <div class="col-12 col-sm-4 mt-4" *ngIf="aseguradora === 'hdi'  " >
            <label class="mb-3">Version</label>
            <select [(ngModel)]="versionSeleccionada" (change)="onDescripcionSeleccionado($event)" class="form-control">
              <option value="" disabled selected>Seleccione una version</option>
              <option *ngFor="let version of versiones" [value]="version.idVehiculo">{{ version.version }}</option>
            </select>
        </div>
            <div class="col-12 col-sm-4 mt-4" *ngIf="aseguradora === 'gnp'  " >
              <label class="mb-3">Version</label>
              <select [(ngModel)]="versionSeleccionada" (change)="onDescripcionSeleccionado($event)" class="form-control">
                <option *ngFor="let version of versiones" [value]="version.clave">{{ version.descr }}</option>
              </select>
          </div>
            <div class="col-12 col-sm-4 mt-4" *ngIf=" aseguradora === 'chubb' " >
                <label class="mb-3">Vehículo</label>
                <select [(ngModel)]="versionSeleccionada" (change)="onDescripcionSeleccionado($event)" class="form-control">
                  <option value="" disabled selected>Seleccione vehiculo</option>
                  <option *ngFor="let version of versiones" [value]="version.id">{{ version.descripcion }}</option>
                </select>
            </div>
            <div class="col-12 col-sm-4 mt-4" *ngIf="aseguradora === 'qualitas' " >
                <label class="mb-3">Año</label>
                <select [(ngModel)]="modeloSeleccionado" (change)="onModeloSeleccionado($event)" class="form-control">
                  <option *ngFor="let modelo of modelos" [value]="modelo">{{ modelo }}</option>
                </select>
              </div>
              


            <div class="col-12 col-sm-4 mt-4" *ngIf=" aseguradora === 'chubb' " >
                <label class="mb-3">Descripcion</label>
                <select [(ngModel)]="descripcionSeleccionada" (change)="onChubb($event)" class="form-control">
                  <option value="" disabled selected>Seleccione version</option>
                  <option *ngFor="let desc of descripcionesChubb" 
                          [value]="desc.cveveh"
                          [attr.data-descripcion]="desc.descripcion">
                    {{ desc.descripcion }}
                  </option>
                </select>
            </div>

              



        </div>

        
  
          <div class="row mt-4">
            <div class="col-12 table-responsive">
              <table *ngIf="coberturasRespuesta" class="col-10 table table-sm align-middle">
                <thead *ngIf="aseguradora === 'qualitas' || aseguradora ==='chubb' || aseguradora ==='gnp' || aseguradora ==='ana' || aseguradora ==='hdi'   || aseguradora ==='ps' ">
                  <tr>
                    <th>Nombre cobertura</th>
                    <th>Suma Asegurada Resultado</th>
                    <th>Deducible Resultado</th>
                    <th *ngIf = "aseguradora !=='gnp'">Prima Resultado</th>
                  </tr>
                </thead>
          
                <tbody *ngIf="aseguradora === 'qualitas'">
                  <tr *ngFor="let key of coberturasRespuesta">
                    <td *ngIf="key">{{ COBERTURAS_QUALITAS[key.NoCobertura] }}</td>
                    <td *ngIf="key">{{ key.SumaAsegurada | currency }}</td>
                    <td *ngIf="key">{{ formatDeducible(key.Deducible) }}</td>
                    <td *ngIf="key">{{ key.Prima | currency }}</td>
                  </tr>
          
                  
                  <tr>
                    <td colspan="3" class="text-end"><strong>Total Primas:</strong></td>
                    <td>{{ calcularSumaPrimas() | currency }}</td>
                  </tr>
                </tbody>
                <tbody *ngIf="aseguradora === 'chubb'">
                  <tr *ngFor="let cobertura of coberturasRespuesta">
                    <td>{{ cobertura.DESC }}</td>
                    <td>{{ cobertura.SUMAASEG | currency }}</td>
                    <td>{{ cobertura.DEDESC }}</td>
                    <td>{{ cobertura.PTOTAL | currency }}</td>
                  </tr>
                  <td colspan="3" class="text-end"><strong>Total Primas:</strong></td>
                    <td>{{ ptotal | currency }}</td>
                </tbody>
                <tbody *ngIf="aseguradora === 'gnp'">
                  <tr *ngFor="let cobertura of coberturasRespuesta">
                    <td>{{ cobertura.NOMBRE }}</td>
                    <td>{{ cobertura.SUMA_ASEGURADA }}</td>
                    <td>{{ cobertura.DEDUCIBLE }}</td>
                  </tr>
                  <td colspan="3" class="text-end"><strong>Total Primas:</strong></td>
                    <td>{{ ptotal | currency }}</td>
                </tbody>
                <tbody *ngIf="aseguradora === 'ps'">
                  <tr *ngFor="let cobertura of coberturasRespuesta">
                    <td>{{ cobertura.id }}</td>
                    <td>{{ cobertura.dsc_sa }}</td>
                    <td>{{ cobertura.dsc_ded }}</td>
                    <td>{{ cobertura.prima_neta | currency }}</td>
                  </tr>
                  <td colspan="3" class="text-end"><strong>Total Primas:</strong></td>
                    <td>{{ ptotal | currency }}</td>
                </tbody>

                <tbody *ngIf="aseguradora === 'ana'">
                  <tr *ngFor="let cobertura of coberturasRespuesta">
                    <td>{{ cobertura['@desc'] }}</td>
                    <td>{{ cobertura['@sa'] }}</td>
                    <td>{{ cobertura['@ded'] }}</td>
                    <td>{{ cobertura['@pma']  }}</td>
                  </tr>
                  <tr>
                    <td *ngIf ="coberturasRespuesta"  colspan="3" class="text-end"><strong>Total Primas:</strong></td>
                    <td>{{ ptotal | currency }}</td>
                  </tr>
                </tbody>

                <tbody *ngIf="aseguradora === 'hdi'">
                  <tr *ngFor="let cobertura of coberturasHDI">
                    <td>{{ cobertura.Descripcion}}</td>
                    <td>{{ cobertura.SumaAsegurada | currency}}</td>
                    <td>{{ cobertura.Deducible }}</td>
                    <td>{{ cobertura.PrimaNeta | currency }}</td>
                  </tr>
                  <tr>
                    <td *ngIf ="coberturasHDI"  colspan="3" class="text-end"><strong>Total Primas:</strong></td>
                    <td>{{ ptotal | currency }}</td>
                  </tr>
                </tbody>
                
              </table>
          
              
            
          
          
  
            <button (click)="probarConexion()" class="btn btn-primary mt-3" type="button">Probar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  