<div class="row">
    <div class="card card-body">
      <div class="col-12 ms-3">
        <div class="col-12 col-sm-6 mt-3">
          <label class="mb-3">Aseguradora</label>
          <select [(ngModel)]="aseguradora" (change)="changeAseguradora()" class="form-control col-6 ">
              <option *ngFor="let aseguradora of aseguradoras" [value]="aseguradora">{{aseguradora}}</option>
          </select>
        </div>



        <div class="col-12 col-sm-6 mt-4">
          <label class="mb-3">Empresa</label>
          <select [(ngModel)]="owner" (change)="changeEmpresa()" class="form-control col-6 ">
              <option *ngFor="let empresa of empresas" [value]="empresa.nombre">{{empresa.nombre}}</option>
          </select>
        </div>
        
        <div class="col-12 mt-3">
          <button class="col-12 col-sm-3 btn btn-dark text-white mt-3 mb-3" (click)="modalCobertura(configModal, {})"><i class="fa fa-plus-square me-2"></i>Nueva credencial</button>
        </div>

      </div>

      <div class="row">
        <div class=" col-12 table-responsive">
            <table class="col-10 table table-sm align-middle">
              <thead>
                <tr >
                  <th>Credenciales</th>
                  <th>Acciones</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let credencial of credenciales; let i = index"  (click)="modalCobertura(configModal, credencial)" style="cursor: pointer;">
                  <td>{{credencial.data }}</td>
                  <td>
                    <button class="btn" (click)="$event.stopPropagation()">
                      <img (click)="deleteCobertura(credencial, i)" src="../../../assets/images/Market/trash.png" alt="" style="cursor: pointer; width: 13px;" >
                    </button>
                  </td>
                  <td></td>
                </tr>
            </table>
        </div>
      </div>
        
        <!-- list admin -->
        <!-- <div class="row" style="margin: 20px; padding: 0 100px;">
            <div class="col-12">
                <div class="d-flex justify-content-between">
                    <div class="col-sm-4 d-flex">
                        <p style="margin: auto 20px;"></p>
                      
                    </div>
                    <div class="col-sm-4 d-flex">
                        <p style="margin: auto 20px;"></p>
                        
                    </div>
                </div>
            </div>
        </div>

        <div class="row" style="margin: 20px; padding: 0 100px;">

            <div class="col-12"  *ngFor="let credencial of credenciales; let i = index"  (click)="modalCobertura(configModal, credencial)">
                <div class="d-flex ">
                    <div class="col-sm-4 d-flex" style="cursor: pointer;" >
                        <p style="margin: auto 0; font-weight: 500;">{{credencial.data }}</p>
                    </div>
                    <div  (click)="$event.stopPropagation()">
                        <img (click)="deleteCobertura(credencial, i)" src="../../../assets/images/Market/trash.png" alt="" style="cursor: pointer;" >
                    </div>
                </div>
                <hr>
            </div>

           

        </div> -->
    </div>
</div>


<ng-template #configModal let-modal>
    <div class="modal-header">
        <h5 *ngIf="!credencial_seleccionada || !credencial_seleccionada.id" class="modal-title" id="editEmpresaLabel">Agregar Credencial</h5>
        <h5 *ngIf="credencial_seleccionada && credencial_seleccionada.id" class="modal-title" id="editEmpresaLabel">Editar Credencial</h5>
        <button type="button" class="btn-close" (click)="closeBtnClick()" aria-label="Close"></button>
      </div>
    
      <div class="modal-body">    
          <div class="row">
            <div class="col-sm-12">
              <label class="col-sm-4 col-form-label">Data</label>
              <div class="col-sm-8">
                <textarea [(ngModel)]="credencial_seleccionada.data" rows="10" type="text" class="form-control" ></textarea>
              </div>
            </div>
            <div class="col-sm-12">
              <label class="col-sm-4 col-form-label">Ambiente</label>
              <div class="col-sm-8">
                <input [(ngModel)]="credencial_seleccionada.env" type="text" class="form-control" />
              </div>
            </div> 
            <div class="col-sm-12">
              <label class="col-sm-4 col-form-label">Activo</label>
              <div class="col-sm-8">
                <input type="checkbox" [(ngModel)]="credencial_seleccionada_active">
              </div>
            </div>
           
        
            
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              (click)="closeBtnClick()"
            >
              Cancelar
            </button>
    
            <button
              type="button"
              class="btn btn-primary"
              (click)="guardarCobertura()"
            >
              Guardar
            </button>
          </div>
      </div>
</ng-template>
