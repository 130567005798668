<div class="row">
  <div class="card card-body">
      <div class="table-responsive">
          <table class="table">
              <tbody>
                  <tr>
                      <td>
                          <div class="col-12 col-sm-6 mt-4">
                            <label class="mb-3">Empresa</label>
                              <select [(ngModel)]="owner" (change)="changeEmpresa()" class="form-control col-6">
                                  <option *ngFor="let empresa of empresas" [value]="empresa.nombre">{{empresa.nombre}}</option>
                              </select>
                          </div>
                      </td>
                  </tr>
                  <tr>
                      <th>Aseguradoras</th>
                  </tr>
                  <tr *ngFor="let aseguradora of aseguradoras">
                      <td>
                          <div class="form-check">
                              <input type="checkbox" class="form-check-input" 
                                     id="aseguradora{{aseguradora.id}}"
                                     [(ngModel)]="aseguradorasSeleccionadas[aseguradora.id]">
                              <label class="form-check-label" for="aseguradora{{aseguradora.id}}">
                                  {{ aseguradora.nombre }}
                              </label>
                          </div>
                      </td>
                  </tr>
              </tbody>
          </table>
          <!-- Botón de guardar -->
          <div class="text-end mt-3">
              <button class="btn mr-0 btn-success" (click)="guardarSeleccion()" type="button">
                  <i class="fa fa-save me-2"></i>Guardar
              </button>
          </div>
      </div>
  </div>
</div>




<notifier-container></notifier-container>