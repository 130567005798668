import { Injectable } from '@angular/core';
import { Formato, Carpeta } from './conexion';
import { AjustesProvider } from '../services/ajustes';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment'
import { Observable } from 'rxjs';
import { Form } from '@angular/forms';


@Injectable()
export class ConexionService {
    public headers:any;
    public headers2:any;
    public url:string;
    qualitas: any;
    chubb: any;
    gnp: any;
    ana: any;
    hdi: any;
    ps: any;

    constructor(
        private _ajustes: AjustesProvider,
        private http: HttpClient,
      ) { 
        this._ajustes.cargarSessionStorage();
        this.url = environment.IP_CONF
        this.qualitas =environment.URL_QUALITAS
        this.chubb =environment.URL_CHUBB
        this.gnp =environment.URL_GNP
        this.ana =environment.URL_ANA
        this.hdi =environment.URL_HDI
        this.ps =environment.URL_PS
        this.headers = { 
          'Content-Type': 'application/json', 
          'Authorization': 'Token '+ this._ajustes.sesionStorage.token
        }
        this.headers2 = { 
          'Content-Type': 'application/json', 
          
        }
      }
    

    public formato: Formato[] = [];

    public getCoberturas(owner:string, aseguradora:string) {
        return this.http.get(this.url + 'cotizar/coberturas/'+aseguradora+'?owner='+owner, {
            headers: this.headers,
            withCredentials: true
        })
    }


    public getEmpresas() {
        return this.http.get(this.url + 'core/empresas', {
            headers: this.headers,
            withCredentials: true
        })
    }

    getMarcasPS(empresa:string, tipo :string){
      return new Promise((resolve,reject) => {
        this.http.get(this.ps+'marcas?empresa='+empresa+'&tipoVehiculo='+tipo)
        .subscribe(data => {
          resolve(data);
        },err => {
          reject(err)
        })
      });
    }

    getsubMarcasPS(empresa:string, tipo :string, marca:string){
      return new Promise((resolve,reject) => {
        this.http.get(this.ps+'submarcas?empresa='+empresa+'&tipoVehiculo='+tipo+'&marcaVehiculo='+marca)
        .subscribe(data => {
          resolve(data);
        },err => {
          reject(err)
        })
      });
    }
    getVehiculosPS(empresa:string, submarca:string, tipo :string, marca:string, modelo:string){
      return new Promise((resolve,reject) => {
        this.http.get(this.ps+'vehiculos?empresa='+empresa+'&subMarcaVehiculo='+submarca+'&tipoVehiculo='+tipo+'&marcaVehiculo='+marca+'&anio='+modelo)
        .subscribe(data => {
          resolve(data);
        },err => {
          reject(err)
        })
      });
    }









    getMarcasChubb(empresa:string){
      return new Promise((resolve,reject) => {
        this.http.get(this.chubb+'marcas/'+empresa, {headers: this.headers})
        .subscribe(data => {
          resolve(data);
        },err => {
          reject(err)
        })
      });
    }






    getModelosAna(empresa:string){
      return new Promise((resolve,reject) => {
        this.http.get(this.ana+'modelos?empresa='+empresa)
        .subscribe(data => {
          resolve(data);
        },err => {
          reject(err)
        })
      });
    }

    getMarcasAna(categoria:string, modelo:string, empresa:string){
      return new Promise((resolve,reject) => {
        this.http.get(this.ana+'marcas?categoria='+categoria+'&modelo='+modelo+'&empresa='+empresa)
        .subscribe(data => {
          resolve(data);
        },err => {
          reject(err)
        })
      });
    }

    getSubMarcasAna(marca:string,categoria:string, modelo:string, empresa:string){
      return new Promise((resolve,reject) => {
        this.http.get(this.ana+'submarcas?marca='+ marca+ '&categoria='+categoria+'&modelo='+modelo+'&empresa='+empresa)
        .subscribe(data => {
          resolve(data);
        },err => {
          reject(err)
        })
      });
    }
    getVehiculosAna(submarca:string, marca:string,categoria:string, modelo:string, empresa:string){
      return new Promise((resolve,reject) => {
        this.http.get(this.ana+'vehiculos?'+'submarca='+submarca+'&marca='+ marca+ '&categoria='+categoria+'&modelo='+modelo+'&empresa='+empresa)
        .subscribe(data => {
          resolve(data);
        },err => {
          reject(err)
        })
      });
    }
    getMarcasGNP(tipo:string){
      return new Promise((resolve,reject) => {
        this.http.get(this.gnp+'marcas?tipo='+tipo)
        .subscribe(data => {
          resolve(data);
        },err => {
          reject(err)
        })
      });
    }
    getSubmarcasChubb(empresa:string, id:string){
      return new Promise((resolve,reject) => {
        this.http.get(this.chubb+'submarcas/' + empresa+'/'+ id, {headers: this.headers})
        .subscribe(data => {
          resolve(data);
        },err => {
          reject(err)
        })
      });
    }
    getSubmarcasGNP(marca:string, tipo :string){
      return new Promise((resolve,reject) => {
        this.http.get(this.gnp+'submarcas?marca=' + marca + '&tipo=' + tipo )
        .subscribe(data => {
          resolve(data);
        },err => {
          reject(err)
        })
      });

    }
    getModelosChubb(marca:string, submarca:string){
      return new Promise((resolve,reject) => {
        this.http.post(this.chubb+'modelos' , {marca:marca,submarca:submarca}, {headers: this.headers})
        .subscribe(data => {
          resolve(data);
        },err => {
          reject(err)
        })
      });
    }
    getModelosGNP(marca:string, submarca:string, tipo:string){
      return new Promise((resolve,reject) => {
        this.http.get(this.gnp+'modelos?marca='+marca+'&submarca='+submarca+'&tipo='+tipo ,  {headers: this.headers})
        .subscribe(data => {
          resolve(data);
        },err => {
          reject(err)
        })
      });
    }
    getDescripcionesChubb(marca:string, submarca:string, modelo:string, empresa:string){
      return new Promise((resolve,reject) => {
        this.http.get(this.chubb+'tipos/' + empresa + '/' + marca + '/' + submarca + '/' + modelo ,  {headers: this.headers})
        .subscribe(data => {
          resolve(data);
        },err => {
          reject(err)
        })
      });
    }
    getDescripcionesGNP(marca:string, submarca:string, modelo:string){
      return new Promise((resolve,reject) => {
        this.http.get(this.gnp+'vehiculos?marca='+ marca + '&submarca=' + submarca + '&modelo=' + modelo ,  {headers: this.headers})
        .subscribe(data => {
          resolve(data);
        },err => {
          reject(err)
        })
      });
    }
    getChubbFinal(empresa:string, tipo:string, modelo:string){
      return new Promise((resolve,reject) => {
        this.http.get(this.chubb+'descr/' + empresa + '/' + tipo + '/' + modelo  ,  {headers: this.headers})
        .subscribe(data => {
          resolve(data);
        },err => {
          reject(err)
        })
      });

    }
    public testConn2(owner:string, aseguradora:string, paquete:string) {
      return this.http.post(this.url + 'cotizar/probar-conexion/'+aseguradora+'?owner='+owner,{paquete, empresa:owner}, {
          headers: this.headers,
          withCredentials: true
      })
  }
  
  public testConnGNP(owner:string, aseguradora:string, paquete:string,modelo:string,version:string,tipo:string) {
    return this.http.post(this.url + 'cotizar/probar-conexion/'+aseguradora+'?owner='+owner,{paquete:paquete, empresa:owner, modelo:modelo, clave:version, tipo:tipo}, {
        headers: this.headers,
        withCredentials: true
    })
}
    getMarcasHDI(tipo:string){
      return new Promise((resolve,reject) => {
        this.http.get(this.hdi+'marcas?tipo='+tipo, {headers: this.headers})
        .subscribe(data => {
          resolve(data);
        },err => {
          reject(err)
        })
      });
    }
    getSubMarcasHDI(marca:string,tipo:string){
      return new Promise((resolve,reject) => {
        this.http.get(this.hdi+'submarcas?marca='+marca+'&tipo='+tipo, {headers: this.headers})
        .subscribe(data => {
          resolve(data);
        },err => {
          reject(err)
        })
      });
    }

    getModelosHDI(marca:string,submarca:string, tipo:string){
      return new Promise((resolve,reject) => {
        this.http.get(this.hdi+'modelos?submarca='+submarca+'&marca='+marca+'&tipo='+tipo, {headers: this.headers})
        .subscribe(data => {
          resolve(data);
        },err => {
          reject(err)
        })
      });
    }
    getVehiculosHDI(modelo:string, marca:string,submarca:string, tipo:string){
      return new Promise((resolve,reject) => {
        this.http.get(this.hdi+'vehiculos?modelo='+modelo+'&submarca='+submarca+'&marca='+marca+'&tipo='+tipo, {headers: this.headers})
        .subscribe(data => {
          resolve(data);
        },err => {
          reject(err)
        })
      });
    }




    getMarcasQualitas(){
        return new Promise((resolve,reject) => {
          this.http.get(this.qualitas+'/marcas', {headers: this.headers})
          .subscribe(data => {
            resolve(data);
          },err => {
            reject(err)
          })
        });
      }
      getSubmarcasQualitas(brand:string){
        return new Promise((resolve,reject) => {
          this.http.get(this.qualitas+'submarcas/' + brand, {headers: this.headers})
          .subscribe(data => {
            resolve(data);
          },err => {
            reject(err)
          })
        });
      }
    
      getModelosQualitas(brand:string, subbrand:string){
        return new Promise((resolve,reject) => {
          this.http.get(this.qualitas+'modelos/' + brand + '/' + subbrand, {headers: this.headers})
          .subscribe(data => {
            resolve(data);
          },err => {
            reject(err)
          })
        });
      }
    
      getDescripcionesQualitas(brand:string, subbrand:string, model:string){
        return new Promise((resolve,reject) => {
          this.http.get(this.qualitas+'/descripcion/' + brand + '/' + subbrand + '/' + model, {headers: this.headers})
          .subscribe(data => {
            resolve(data);
          },err => {
            reject(err)
          })
        });
      }


    public getAseguradoras() {
        return this.http.get(this.url + 'core/aseguradoras', {
            headers: this.headers,
            withCredentials: true
        })
    }

    public deleteCoberturas(id:number, aseguradora:string) {
        return this.http.delete(this.url + 'cotizar/coberturas/'+aseguradora+'?id='+id, {
            headers: this.headers,
            withCredentials: true
        })
    }
    public updateCobertura(cobertura:string, aseguradora:string) {
        return this.http.patch(this.url + 'cotizar/coberturas/'+aseguradora+'', cobertura, {
            headers: this.headers,
            withCredentials: true
        })
    }
    public createCoberturas(cobertura:string, aseguradora:string) {
        return this.http.post(this.url + 'cotizar/coberturas/'+aseguradora+'', cobertura,  {
            headers: this.headers,
            withCredentials: true
        })
    }
    public createEmpresa(empresa:string) {
        return this.http.post(this.url + 'core/empresas/', {nombre: empresa},  {
            headers: this.headers,
            withCredentials: true
        })
    }
    
    public testConn(owner:string, aseguradora:string, paquete:string,clave:string, modelo:string, categoria:string) {
        return this.http.post(this.url + 'cotizar/probar-conexion/'+aseguradora+'?owner='+owner,{paquete, empresa:owner, vehiculo:clave, modelo:modelo, cat:categoria}, {
            headers: this.headers,
            withCredentials: true
        })
    }
    public testConnChubb(owner:string, aseguradora:string, paquete:string,clave:string, modelo:string, descripcion:string) {
      return this.http.post(this.url + 'cotizar/probar-conexion/'+aseguradora+'?owner='+owner,{paquete, empresa:owner, clave:clave, modelo:modelo, desc: descripcion}, {
          headers: this.headers,
          withCredentials: true
      })
  }
    
}
