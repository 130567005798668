<div class="row">
    <div class="card card-body">
      <div class="table-responsive">
        <table class="table">
            <thead>
                <tr>
                    <th>Nombre</th>
                    <th>Empresa</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let usuario of usuarios; let i=index">
                    <td>{{usuario.username}}</td>
                    <td>
                      <div class="input-group mb-3">
                        <select class="form-control" [(ngModel)]="usuario.userprofile.empresa">
                          <option *ngFor="let empresa of empresas; let i=index" [value]="empresa.url">{{empresa.nombre}}</option>
                        </select>
                        <button class="btn mr-0 btn-success" (click)="actualizarUsuario(usuario.userprofile)" type="button"><i class="fa fa-save me-2"></i></button>
                      </div>
                    </td>
                </tr>
            </tbody>
        </table>
  
    </div>
</div>

<notifier-container></notifier-container>