<div class="row">
    <div class="card card-body">

      <div class="col-12 ms-3">

        <div class="col-12 col-sm-6 mt-3">
          <label class="mb-3">Aseguradora</label>
          <select [(ngModel)]="aseguradora" (change)="changeAseguradora()" class="form-control col-6 ">
              <option *ngFor="let aseguradora of aseguradoras" [value]="aseguradora">{{aseguradora}}</option>
          </select>
        </div>

        <div class="col-12 col-sm-6 mt-4">
          <label class="mb-3">Negocio</label>
          <select [(ngModel)]="owner" (change)="changeEmpresa()" class="form-control col-6 ">
              <option *ngFor="let empresa of empresas" [value]="empresa.nombre">{{empresa.nombre}}</option>
          </select>
        </div>



        <div class="col-12  mt-3 d-block">
          <button class="col-12 col-md-3 btn btn-dark text-white mt-3 mb-3" (click)="modalCobertura(configModal, {})"><i class="fa fa-plus-square me-2"></i>Nuevo descuento</button>
        </div>


      </div>

      <div class="row">
        <div class=" col-12 table-responsive">
            <table class="col-10 table table-sm align-middle">
              <thead>
                <tr>
                  <th>Descuento (%)</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let descuento of descuentos; let i = index"  (click)="modalCobertura(configModal, descuento)" style="cursor: pointer;">
                  <td>{{descuento.descuento}}</td>
                  <td>
                    <button class="btn" (click)="$event.stopPropagation()">
                      <img (click)="deleteCobertura(descuento, i)" src="../../../assets/images/Market/trash.png" alt="" style="cursor: pointer; width: 13px;" >
                    </button>
                  </td>
                </tr>
            </table>
        </div>
      </div>

        
        <!-- list admin -->
        <!-- <div class="row" style="margin: 20px; padding: 0 100px;">
            <div class="col-12">
                <div class="d-flex justify-content-between">
                    <div class="col-sm-4 d-flex">
                        <p style="margin: auto 20px;">Descuento (%)</p>
                      
                    </div>
                </div>
            </div>
        </div>

        <div class="row" style="margin: 20px; padding: 0 100px;">

            <div class="col-12"  *ngFor="let descuento of descuentos; let i = index"  (click)="modalCobertura(configModal, descuento)">
                <div class="d-flex ">
                    <div class="col-sm-4 d-flex" style="cursor: pointer;" >
                        <p style="margin: auto 0; font-weight: 500;">{{descuento.descuento}}</p>
                    </div>
                  
                    <div  (click)="$event.stopPropagation()">
                        <img (click)="deleteCobertura(descuento, i)" src="../../../assets/images/Market/trash.png" alt="" style="cursor: pointer;" >
                    </div>
                </div>
                <hr>
            </div>

           

        </div> -->
    </div>
</div>


<ng-template #configModal let-modal>
    <div class="modal-header">
        <h5 *ngIf="!descuento_seleccionado || !descuento_seleccionado.id" class="modal-title" id="editEmpresaLabel">Agregar Descuento</h5>
        <h5 *ngIf="descuento_seleccionado && descuento_seleccionado.id" class="modal-title" id="editEmpresaLabel">Editar Descuento</h5>
        <button type="button" class="btn-close" (click)="closeBtnClick()" aria-label="Close"></button>
      </div>
    
      <div class="modal-body">    
          <div class="row">
            <div class="col-sm-12">
              <label class="col-sm-4 col-form-label">Descuento</label>
              <div class="col-sm-8">
                <input [(ngModel)]="descuento_seleccionado.descuento" type="text" class="form-control" />
              </div>
            </div> 
           
            
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              (click)="closeBtnClick()"
            >
              Cancelar
            </button>
    
            <button
              type="button"
              class="btn btn-primary"
              (click)="guardarCobertura()"
            >
              Guardar
            </button>
          </div>
      </div>
</ng-template>




<ng-template #empresaModal let-modal>
    <div class="modal-header">
        <h5 class="modal-title" id="editEmpresaLabel">Agregar Empresa</h5>
        <button type="button" class="btn-close" (click)="closeBtnClick()" aria-label="Close"></button>
      </div>
    
      <div class="modal-body">    
          <div class="row">
            <div class="col-sm-12">
              <label class="col-sm-4 col-form-label">Nombre</label>
              <div class="col-sm-8">
                <input [(ngModel)]="nueva_empresa" type="text" class="form-control" />
              </div>
            </div> 
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              (click)="closeBtnClick()"
            >
              Cancelar
            </button>
    
            <button
              type="button"
              class="btn btn-primary"
              (click)="guardarEmpresa()"
            >
              Guardar
            </button>
          </div>
      </div>
</ng-template>


