import { Component } from '@angular/core';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { AjustesProvider } from '../services/ajustes';
import { FormatoService } from './formatos.service';
import { NgbHighlight, NgbModal, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { AbstractControl, FormGroup, FormsModule, ReactiveFormsModule, FormArray, FormControl, FormBuilder } from '@angular/forms';
import { CommonModule, formatDate, NumberFormatStyle } from '@angular/common';
import { FeatherModule } from 'angular-feather';
import { ChangeDetectorRef } from '@angular/core';





@Component({
  selector: 'app-formatos',
  templateUrl: './formatos.component.html',
  styleUrls: [],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbPaginationModule,
    FeatherModule,
    NgbHighlight
    
  ],
  standalone: true,
  providers: [
    FormatoService
  ]
})
export class FormatosComponent {
  COBERTURAS_QUALITAS = {    
    1: "Daños Materiales", 
    2: "DM Sólo pérdida total", 
    3: "Robo Total", 
    4: "Responsabilidad Civil", 
    5: "Gastos Médicos", 
    6: "Muerte del Conductor X Accidente", 
    7: "Gastos Legales", 
    8: "Equipo Especial [descripción del equipo | suma asegurada]", 
    9: "Adaptaciones Daños Materiales [descripción de la adaptación | suma asegurada]", 
    10: "Adaptaciones Robo Total [descripción de la adaptación | suma asegurada]", 
    11: "Extensión de RC", 
    12: "Exención de Deducible Daños Materiales", 
    13: "RC Pasajero", 
    14: "Asistencia Vial", 
    17: "Gastos de Transporte/Gastos X Perdida de Uso X Pérdidas Totales", 
    22: "RC Legal Ocupantes", 
    26: "Cancelación de deducible por vuelco o colisión", 
    28: "Gastos X Perdida de Uso X Perdidas Parciales", 
    31: "Daños por la carga. Tipo Carga [A o B o C] | [Descrip de la Carga] | [Num Remolques]", 
    40: "Exención de Deducible Robo Total",
    34: "RC en el Extranjero",
    47: "RC Complementaria Personas"
  };

  COBERTURAS_HDI ={
    249:'Asistencia en viajes',
    239:'Gastos medicos ocupantes (Limite unico combinado)',
    253:'Responsabilidad Civil',
    242:'Asistencia Juridica',
    233:'Danos materiales',
    235:'Accidentes Automovilisticos al conductor',
    266:'Responsabilidad USA y Canada',
    236:'Robo Total',
    366:'Responsabilidad civil exceso por muerte'


  }
  COBERTURAS_CHUBB ={
    1: "Daños materiales",
    2: "Robo total",
    3: "Responsabilidad civil por daños a terceros",
    4: "Gastos Médicos Ocupantes",
    6: "Muerte del conductor por accidente",
    7: "Responsabilidad civil carga A",
    8: "Adaptaciones y conversiones",
    9: "Equipo especial",
    11: "Responsabilidad civil carga B",
    12: "Responsabilidad civil carga C / Ecológica",
    14: "Responsabilidad civil primer remolque",
    15: "Responsabilidad civil segundo remolque",
    20: "Cobertura por diferencial (CODIF)",
    21: "Cláusula de extensión de cobertura limitada (CEL)",
    22: "Robo parcial (Parte por parte)",
    25: "Cláusula de aumento porcentual (CAP Daños materiales)",
    33: "Auto protegido EMME",
    41: "Extensión de cobertura de responsabilidad civil",
    42: "Asistencia legal IKE",
    43: "Responsabilidad civil equipo especial",
    44: "Responsabilidad civil adaptaciones y conversiones",
    45: "Cláusula de aumento porcentual (CAP Robo total)",
    46: "Auto relevo pérdida total",
    47: "Responsabilidad civil por daños a ocupantes",
    49: "Accidentes automovilísticos al conductor",
    78: "Asistencia legal PROVIAL",
    79: "Auto protegido EMERMEX",
    84: "Auto relevo integral",
    110: "Responsabilidad civil familiar",
    114: "Gestoría vial",
    115: "Maniobras de carga y descarga",
    117: "Auto relevo premium",
    118: "Auto relevo pérdida total premium",
    120: "Seguro de llantas y rines",
    393: "Auto relevo plus",
    394: "Auto relevo plus premium",
    467: "Asistencia legal AUSA",
    468: "Asistencia legal OESA",
    469: "Asistencia legal Logistics",
    470: "Asistencia legal y vial IKE",
    471: "Cerocible pérdida total daños",
    472: "Cerocible",
    473: "Responsabilidad civil por fallecimiento",
    486: "Responsabilidad civil por fallecimiento plus",
    524: "Gastos de transporte por pérdida total",
    549: "Reparación en agencia",
    552: "Responsabilidad civil en USA y Canadá",
    561: "Responsabilidad civil personas",
    702: "Cerocible pérdida total robo",
    703: "Auto protegido SIAM",
    726: "Responsabilidad civil viajero",
    732: "Accidentes personales",
    800: "Responsabilidad civil bienes",
    801: "Asistencia legal plus PROVIAL",
    856: "Asistencia en viaje CDS",
    858: "Asistencia legal CDS",
    859: "Asistencia legal y vial CDS",
    862: "Auto protegido CDS",
    863: "Auto relevo pérdida total CDS",
    864: "Auto relevo plus CDS",
    865: "Auto relevo pérdida total premium CDS",
    866: "Auto relevo plus premium CDS",
    974: "Pérdida o robo de llaves",
    975: "Robo de contenidos personales",
    976: "Responsabilidad civil en exceso"
}
COBERTURAS_ANA = {
  2: "Daños Materiales",
  4: "Robo Total",
  6: "Gastos Médicos",
  7: "Defensa Jurídica",
  8: "NPD x PT x DM", 
  9: "Auto Sustituto",
  10: "ANA Asistencia",
  12: "Extensión de Coberturas",
  13: "Muerte Accidental",  
  18: "ANA Rent",
  23: "RC Ocupantes",
  24: "NPD x PP x DM",
  25: "RC Bienes",
  26: "RC Personas",
  27: "RC USA",
  28: "Segullantas", 
  29: "Extensión Autorent",
  33: "Robo Parcial",
  34: "RC Catastrófica",
  35: "Desbielamiento",
  37: "Grúa",
  38: "Devolución de Prima y Deducible",  
  39: "Reparación en Taller",
  40: "Multas y Corralones"
}
COBERTURAS_PS = {
  'DM': 'Daños materiales',
  'RT': 'Robo Total',
  'RCTP': 'Responsabilidad Civil Terceros en sus Personas',
  'RCTB': 'Responsabilidad Civil Terceros en sus Bienes',
  'RCTBP': 'Responsabilidad Civil Terceros en sus Bienes y Personas',
  'GMO': 'Gastos Médicos Ocupantes',
  'DJUR': 'Defensa Jurídica',
  'RCV': 'Responsabilidad Civil Viajero',
  'EE': 'Equipo Especial',
  'GMC': 'Gastos Médicos al Conductor',
  'GMF': 'Gastos Médicos a Familiares del Conductor',
  'NPD': 'No pago de deducible por pérdida total en daños materiales',
  'ASVI': 'Asistencia Vial',
  'SPT': 'Sólo Pérdida Total',
  'UGF': 'Últimos Gastos',
  'AS': 'Automóvil Sustituto',
  'ExtRCP': 'Extensión de RC a Terceros en sus Personas',
  'ExtRCB': 'Extensión de RC a Terceros en sus Bienes',
  'EXTRCTBP': 'Extensión de RC a Terceros en sus Bienes y Personas',
  'RCCP': 'Daños por la Carga a Terceros en sus Personas',
  'RCCB': 'Daños por la Carga a Terceros en sus Bienes',
  'RCCTB': 'Daños por la Carga a Terceros en sus Bienes y Personas',
  'PC': 'Pérdida de cuenta',
  'EXTRTV': 'Extensión de Gastos Médicos por robo con violencia',
  'AD&CON': 'Adaptaciones y conversiones',
  'RED': 'RED',
  'GV': 'GV',
  'RCUSA': 'RCUSA',
  'EXCRCP': 'EXCRCP',
  'AAC': 'AAC'
};



 COBERTURAS_GNP = {
  '0000000893': {
    id: '0000000893',
    nombre: 'Accidentes Automovilisticos al conductor',
    sumaAsegurada: 100000,
    suma_asegurada: [20000, 50000, 100000, 150000, 200000, 250000, 300000],
    deducible: 0,
    opcionesDeducible: []
  },
  '0000000894': {
    id: '0000000894',
    nombre: 'Adaptaciones, conversiones y Equipo especial',
    sumaAsegurada: 'Amparada',
    suma_asegurada: [],
    deducible: 25,
    opcionesDeducible: [25]
  },
  '0000001414': {
    id: '0000001414',
    nombre: 'Auto Sustituto',
    sumaAsegurada: 10,
    suma_asegurada: [10, 15, 20, 30],
    deducible: 0,
    opcionesDeducible: []
  },
  '0000001415': {
    id: '0000001415',
    nombre: 'Auto Sustituto Plus',
    sumaAsegurada: 10,
    suma_asegurada: [10, 15, 20, 30],
    deducible: 0,
    opcionesDeducible: []
  },
  '0000001348': {
    id: '0000001348',
    nombre: 'Ayuda para Pérdidas Totales',
    sumaAsegurada: 7500,
    suma_asegurada: [7500, 15000],
    deducible: 0,
    opcionesDeducible: []
  },
  '0000001268': {
    id: '0000001268',
    nombre: 'Club GNP',
    sumaAsegurada: 'Amparada',
    suma_asegurada: [],
    deducible: 0,
    opcionesDeducible: []
  },
  '0000000891': {
    id: '0000000891',
    nombre: 'Cristales',
    sumaAsegurada: 'Amparada',
    suma_asegurada: [],
    deducible: 20,
    opcionesDeducible: [20]
  },
  '0000001289': {
    id: '0000001289',
    nombre: 'Daños Materiales Pérdida Parcial',
    sumaAsegurada: 'N/A',
    suma_asegurada: [],
    deducible: 3,
    opcionesDeducible: [3, 5, 10]
  },
  '0000001288': {
    id: '0000001288',
    nombre: 'Daños Materiales Pérdida Total',
    sumaAsegurada: 'N/A',
    suma_asegurada: [],
    deducible: 0,
    opcionesDeducible: [0, 3, 5, 10]
  },
  '0000000904': {
    id: '0000000904',
    nombre: 'Extensión de Responsabilidad Civil',
    sumaAsegurada: 1000000,
    suma_asegurada: [1000000, 1500000, 2000000, 2500000, 3000000, 3500000, 4000000, 4500000, 5000000],
    deducible: 0,
    opcionesDeducible: []
  },
  '0000000906': {
    id: '0000000906',
    nombre: 'Gastos Médicos Ocupantes',
    sumaAsegurada: 200000,
    suma_asegurada: [100000, 120000, 150000, 200000, 300000, 400000, 500000, 600000, 700000, 800000, 900000, 1000000],
    deducible: 0,
    opcionesDeducible: []
  },
  '0000000909': {
    id: '0000000909',
    nombre: 'Protección Auxiliar',
    sumaAsegurada: 'Amparada',
    suma_asegurada: [],
    deducible: 0,
    opcionesDeducible: []
  },
  '0000001285': {
    id: '0000001285',
    nombre: 'Protección legal',
    sumaAsegurada: 1000000,
    suma_asegurada: [1000000, 1500000, 2000000, 2500000, 3000000, 3500000, 4000000, 4500000, 5000000],
    deducible: 0,
    opcionesDeducible: []
  },
  '0000001453': {
    id: '0000001453',
    nombre: 'Responsabilidad Civil Ocupantes',
    sumaAsegurada: 1000000,
    suma_asegurada: [1000000, 1500000, 2000000, 2500000, 3000000, 3500000, 4000000, 4500000, 5000000],
    deducible: 0,
    opcionesDeducible: []
  },
  '0000001273': {
    id: '0000001273',
    nombre: 'Responsabilidad Civil por Daños a Terceros',
    sumaAsegurada: 1000000,
    suma_asegurada: [1000000, 1500000, 2000000, 2500000, 3000000, 3500000, 4000000, 4500000, 5000000],
    deducible: 0,
    opcionesDeducible: []
  },
  '0000001452': {
    id: '0000001452',
    nombre: 'Responsabilidad Civil Fallecimiento',
    sumaAsegurada: 500000,
    suma_asegurada: [500000, 1000000, 1500000, 2000000, 2500000, 3000000, 5000000],
    deducible: 0,
    opcionesDeducible: []
  },
  '0000000916': {
    id: '0000000916',
    nombre: 'Robo total',
    sumaAsegurada: 'N/A',
    suma_asegurada: [],
    deducible: 0,
    opcionesDeducible: [0, 3, 5, 10, 20]
  },
  '0000001470': {
    id: '0000001470',
    nombre: 'Ayuda Llantas/Rines',
    sumaAsegurada: 25000,
    suma_asegurada: [25000],
    deducible: 25,
    opcionesDeducible: [25]
  },
  '0000001471': {
    id: '0000001471',
    nombre: 'Llaves de repuesto',
    sumaAsegurada: 5000,
    suma_asegurada: [5000],
    deducible: 20,
    opcionesDeducible: [20]
  },
  
  '0000001473': {
    id: '0000001473',
    nombre: 'Siempre en Agencia',
    sumaAsegurada: 'Amparada',
    suma_asegurada: [],
    deducible: 0,
    opcionesDeducible: []
  },
  '0000001461': {
    id: '0000001461',
    nombre: 'Robo Parcial',
    sumaAsegurada: 'Amparada',
    suma_asegurada: [],
    deducible: 10,
    opcionesDeducible: [10, 25]
  },
  '0000001472': {
    id: '0000001472',
    nombre: 'Robo Parcial Plus',
    sumaAsegurada: 'Amparada',
    suma_asegurada: [],
    deducible: 10,
    opcionesDeducible: [10, 25]
  },
  '0000001564': {
    id: '0000001564',
    nombre: 'Conductor Protegido',
    sumaAsegurada: 'Amparada',
    suma_asegurada: [],
    deducible: 0,
    opcionesDeducible: []
  },
  '0000001465': {
    id: '0000001465',
    nombre: 'Garantia Autos Financiamiento',
    sumaAsegurada: 'Amparada',
    suma_asegurada: [],
    deducible: 0,
    opcionesDeducible: []
  },
  '0000001567': {
    id: '0000001567',
    nombre: 'Asistencia Vial Moto',
    sumaAsegurada: 'Amparada',
    suma_asegurada: [],
    deducible: 0,
    opcionesDeducible: []
  }
};


sumaAsegurada: { [key: string]: number } = {};
deducibleGNP: { [key: string]: number } = {};
tipoOpciones: string[] = ['Amplia', 'RC', 'Limitada'];
XML:any;

  paquetesUnicos: any = []
  coberturas: any = [];
  coberturasPS: any = [];
  coberturasPorPaquete :any;
  coberturasRespuesta : any =[];
  owner: string = 'Seleccione una opción';
  aseguradora: string = 'Seleccione una opción';
  nueva_empresa: any;
  provider: string = '';
  empresas: any = [];
  coberturas_procesadas:any=[];
  aseguradoras: any = [];
  idsSeleccionados: number[] = [];
  groupedCoberturas: { nombre: string; options: { insuredSum: number; deductible: number }[] }[] = [];
  selectedCoberturas_: { [key: number]: boolean } = {};
  id :number;
  paquete:string;
  nombre:string;
  idsCoberturas: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9,10,11,12,13,14,15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31,33,34,35]; 
  agrupadasCoberturas: { [key: string]: any[] } = {};
  cobertura_seleccionada: any = {
    paquete: "",
    nombre: "",
    clave: "",
    suma_asegurada: 0,
    deducible: 0,
    coberturas_ids: []
  };
  
  selectedCoberturas: any = {}; 
  coberturaSeleccionada: any = {}; 
  coberturasFiltradas: any;
  tipoCobertura: string;
 

  constructor(
    private _router: Router,
    private _ajustes: AjustesProvider,
    private formatoService : FormatoService,
    private modalService: NgbModal,
    private cdRef: ChangeDetectorRef,
    

    private fb: FormBuilder

  ){
    
    window.scrollTo(0, 0);
    this.formatoService.getEmpresas().subscribe((response:any) =>{
      this.empresas = response;
      this.empresas.unshift({nombre:'Seleccione una opción'});
    });
    
    this.formatoService.getAseguradoras().subscribe((response:any) =>{
      this.aseguradoras = response;
      this.aseguradoras.unshift('Seleccione una opción');
    });
    const todasLasCoberturas = Array.from({ length: 32 }, (_, i) => this.obtenerCoberturaPorId(i + 1)).filter(Boolean);
      this.agrupadasCoberturas = todasLasCoberturas.reduce((acc, cobertura) => {
        if (!acc[cobertura.nombre]) {
          acc[cobertura.nombre] = [];
        }
        acc[cobertura.nombre].push(cobertura);
        return acc;
      }, {} as { [key: string]: any[] });
  }

  

  obtenerCoberturaPorId(id: number) {
    switch (id) {
      case 1:
        return {
          nombre: 'Gastos médicos ocupantes',
          insuredSum: 300000,
          deductible: 0
        };
      case 2:
        return {
          nombre: 'Gastos médicos ocupantes',
          insuredSum: 350000,
          deductible: 0
        };
      case 3:
        return {
          nombre: 'Gastos médicos ocupantes',
          insuredSum: 400000,
          deductible: 0
        };
        case 4:
        return {
          nombre: 'Gastos médicos ocupantes',
          insuredSum: 500000,
          deductible: 0
        };
      case 5:
        return {
          nombre: 'Responsabilidad Civil Personas',
          insuredSum: 1000000,
          deductible: null
        };
      case 6:
        return {
          nombre: 'Responsabilidad Civil Personas',
          insuredSum: 1500000,
          deductible: null
        };
      case 7:
        return {
          nombre: 'Responsabilidad Civil Personas',
          insuredSum: 2000000,
          deductible: null
        };
      case 8:
        return {
          nombre: 'Responsabilidad Civil Muerte',
          insuredSum: 500000,
          deductible: null
        };
      case 9:
        return {
          nombre: 'Responsabilidad Civil Muerte',
          insuredSum: 1000000,
          deductible: null
        };
      case 10:
        return {
          nombre: 'Responsabilidad Civil Muerte',
          insuredSum: 1500000,
          deductible: null
        };
      case 11:
        return {
          nombre: 'Responsabilidad Civil Bienes',
          insuredSum: 1000000,
          deductible: null
        };
      case 12:
        return {
          nombre: 'Responsabilidad Civil Bienes',
          insuredSum: 1500000,
          deductible: null
        };
      case 13:
        return {
          nombre: 'Responsabilidad Civil Bienes',
          insuredSum: 2000000,
          deductible: null
        };
      case 17:
        return {
          nombre: 'Daños materiales',
          insuredSum: null,
          deductible: 3,
          opcion_deducible_perdida_total: true
        };
      case 18:
        return {
          nombre: 'Daños materiales',
          insuredSum: null,
          deductible: 3,
          opcion_deducible_perdida_total: false
        };
      case 19:
        return {
          nombre: 'Daños materiales',
          insuredSum: null,
          deductible: 5,
          opcion_deducible_perdida_total: true
        };
      case 20:
        return {
          nombre: 'Daños materiales',
          insuredSum: null,
          deductible: 5,
          opcion_deducible_perdida_total: false
        };
      case 21:
        return {
          nombre: 'Daños materiales',
          insuredSum: null,
          deductible: 7,
          opcion_deducible_perdida_total: true
        };
      case 22:
        return {
          nombre: 'Daños materiales',
          insuredSum: null,
          deductible: 7,
          opcion_deducible_perdida_total: false
        };
      case 23:
        return {
          nombre: 'Robo total',
          insuredSum: null,
          deductible: 0
        };
      case 24:
        return {
          nombre: 'Robo total',
          insuredSum: null,
          deductible: 5
        };
      case 25:
        return {
          nombre: 'Robo total',
          insuredSum: null,
          deductible: 10
        };
      case 26:
        return {
          nombre: 'Robo total',
          insuredSum: null,
          deductible: 15
        };

        case 27:
        return {
          nombre: 'Robo parcial',
          insuredSum: null,
          deductible: 5
        };

        case 28:
        return {
          nombre: 'Robo parcial',
          insuredSum: null,
          deductible: 10
        };
        case 29:
        return {
          nombre: 'Robo parcial',
          insuredSum: null,
          deductible: 15
        };
      case 30:
        return {
          nombre: 'Accidentes al conductor',
          insuredSum: 50000,
          deductible: 0
        };
      case 31:
        return {
          nombre: 'Accidentes al conductor',
          insuredSum: 100000,
          deductible: 0
        };
      case 32:
        return {
          nombre: 'Accidentes al conductor',
          insuredSum: 150000,
          deductible: 0
        };
      case 33:
      return {
        nombre: 'Asistencia legal',
        insuredSum: '',
        deductible: 0
      };
      case 34:
      return {
        nombre: 'Asistencia vial y en viajes',
        insuredSum: '',
        deductible: 0
      };
      case 35:
      return {
        nombre: 'Lazy fee',
        insuredSum: '',
        deductible: 0
      };
      default:
        return null;
    }
  }
  onTipoChange(option: string): void {
    
    if (this.aseguradora ==='hdi' || this.aseguradora ==='ps'){
      this.selectedCoberturas ={}
    switch (option) {
      case 'Amplia':
        this.paquete = 'Amplia';
        break;
      case 'Limitada':
        this.paquete = 'Limitada';
        break;
      case 'RC':
        this.paquete = 'RC';
        break;
      
      default:
        this.paquete = '';
    }if (this.aseguradora ==='hdi'){
    this.formatoService.getCoberturasHDI(this.owner, this.paquete).subscribe((response) => {
      this.coberturas=response
      
        this.procesarCoberturas();

        const paquetesUnicos = new Set();
          const coberturasPorPaquete = {};
          this.coberturas.forEach((cobertura:any) => {
            const cobID = cobertura.cobID;
            const paquete = cobertura.paquete;
            if (paquete) {
              paquetesUnicos.add(paquete);  
              if (!coberturasPorPaquete[paquete]) {
                coberturasPorPaquete[paquete] = [];
              }
              coberturasPorPaquete[paquete].push(cobertura);
            }
            this.selectedCoberturas[cobID] = cobID;
            this.selectedCoberturas[cobID + '_clave'] = cobID;
            this.selectedCoberturas[cobID + '_regla'] = cobertura.regla;
            this.selectedCoberturas[cobID + '_descripcion'] = cobertura.nombre;
            this.selectedCoberturas[cobID + '_sumaAsegurada'] = cobertura.sumaAsegurada;
            this.selectedCoberturas[cobID + '_id'] = cobertura.id;
            const deducible = cobertura.deducible
            this.selectedCoberturas[cobID + '_deducible'] = deducible;
            this.selectedCoberturas[cobID + '_tipoCobertura'] = cobertura.tipoCobertura;
          });
          this.paquetesUnicos = Array.from(paquetesUnicos);
          this.selectedCoberturas['coberturasPorPaquete'] = coberturasPorPaquete;
          this.coberturasPorPaquete = coberturasPorPaquete;
        })
      }
      }
    }

  updateDanosMaterialesId() {
    const deductible = this.selectedCoberturas['danos_materiales_deductible'];
    const opcion = this.selectedCoberturas['danos_materiales_opcion'] === 'true'; 
    let id: number;
    switch (deductible) {
      case '3':
        id = opcion ? 17 : 18;
        break;
      case '5':
        id = opcion ? 19 : 20;
        break;
      case '7':
        id = opcion ? 21 : 22;
        break;
      default:
        id = null;
    }
    this.selectedCoberturas['danos_materiales_id'] = id;
  }
  agrupaCoberturasPorPaquete() {
    return this.coberturas.reduce((agrupadas, cobertura) => {
      const paquete = cobertura.paquete;
      if (!agrupadas[paquete]) {
        agrupadas[paquete] = [];
      }
      agrupadas[paquete].push(cobertura);
      return agrupadas;
    }, {});
  }
  

  getSelectedCoberturas() {
    const seleccionadas = Object.keys(this.selectedCoberturas).filter(id => this.selectedCoberturas[+id]);
    return seleccionadas;
  }
  
isCoberturaSeleccionada(id: string): boolean {
  return this.selectedCoberturas.some(cobertura => cobertura.id === id);
}


onCheckboxChangeGNP(key: string, cobertura: any, event: any) {
  if (this.aseguradora ==='gnp'){
  if (event.target.checked) {
    this.selectedCoberturas.push({
      id: key,
      nombre: cobertura.nombre,
      sumaAsegurada: cobertura.sumaAsegurada, 
      deducible: cobertura.deducible
    });
    
    this.sumaAsegurada[key] = cobertura.sumaAsegurada; 
    this.deducibleGNP[key] = cobertura.deducible
  } else {
    this.selectedCoberturas = this.selectedCoberturas.filter(c => c.id !== key);
    delete this.sumaAsegurada[key]; 
    delete this.deducibleGNP[key]; 
  }}
  if(this.aseguradora==='ps'){
    if (event.target.checked) {
      this.selectedCoberturas.push({
        id: key,
        sumaAsegurada: cobertura.sumaAsegurada, 
        deducible: cobertura.deducible
      });
      
      this.sumaAsegurada[key] = cobertura.sumaAsegurada; 
      this.deducibleGNP[key] = cobertura.deducible
    } else {
      this.selectedCoberturas = this.selectedCoberturas.filter(c => c.id !== key);
      delete this.sumaAsegurada[key]; 
      delete this.deducibleGNP[key]; 
    }

  }


}


getSumaAseguradaGNP(key: string): number {
  const cobertura = this.selectedCoberturas.find(c => c.id === key);
  return cobertura ? cobertura.sumaAsegurada : null;
}


opcionesDeducible(id: string): number[] {
  const cobertura = this.selectedCoberturas.find(c => c.id === id);
  return cobertura?.opcionesDeducible || [0]; 
}

onSumaAseguradaChangeGNP(event: any, key: string) {
  const selectedCobertura = this.selectedCoberturas.find(cobertura => cobertura.id === key);
  if (selectedCobertura) {
    selectedCobertura.sumaAsegurada = event;
  }
  
}


onDeducibleChangeGNP(deducible: number, key: string): void {
  const cobertura = this.selectedCoberturas.find(c => c.id === key);
  if (cobertura) {
    cobertura.deducible = deducible;
  }
  
}





getDeducibleGNP(id: string): number | null {
  const cobertura = this.selectedCoberturas.find(c => c.id === id);
  return cobertura ? cobertura.deducible : null;
}


  
  
  onSumaAseguradaInput(event: any, key: string): void {
    const inputElement = event.target as HTMLInputElement;
    const rawValue = inputElement.value.replace(/,/g, ''); 
    const numericValue = parseFloat(rawValue) || 0;
    const cobertura = this.selectedCoberturas[`${key}_id`];
    
    
    if ( this.aseguradora ==='ana' || this.aseguradora==='hdi' ) {
      const cobertura = this.coberturas.find(c => c.cobID === parseInt(key));
      if (cobertura) {
        cobertura.sumaAsegurada = numericValue;
        cobertura.data = this.generarXML(cobertura);
        
      } else {
        console.warn(`No se encontró la cobertura con el id: ${key}`);
        cobertura.sumaAsegurada = numericValue;
        cobertura.data = this.generarXML(cobertura);
      }
    } if (this.aseguradora === 'chubb'  ) {
      const cobertura = this.coberturas.find(c => c.noCobertura === parseInt(key));
      if (cobertura) {
        cobertura.sumaAsegurada = numericValue;
        cobertura.data = this.generarXML(cobertura);
      } else {
        console.warn(`No se encontró la cobertura con el id: ${key}`);
        cobertura.sumaAsegurada = numericValue;
        cobertura.data = this.generarXML(cobertura);
      }
    }
    else if (this.aseguradora === 'qualitas') {
       const cobertura = this.coberturas.find(c => c.noCobertura === parseInt(key));
       if (cobertura) {
        cobertura.sumaAsegurada = numericValue;
        cobertura.data = this.generarXML(cobertura);
        
      } else {
        console.warn(`No se encontró la cobertura con el id: ${key}`);
      }
    } else if (this.aseguradora === 'gnp' ) {
      const cobertura = this.coberturasFiltradas.find(c => c.id === key);
      if (cobertura) {
        cobertura.sumaAsegurada = numericValue;
        cobertura.data = this.generarXML(cobertura);
        
      } else {
        console.warn(`No se encontró la cobertura con el id: ${key}`);
      }
    }else if (this.aseguradora === 'ps' ) {
      const cobertura = this.coberturasPS.find(c => c.id === key);
      if (cobertura) {
        cobertura.sumaAsegurada = numericValue;
        cobertura.data = this.generarXML(cobertura);
        this.XML = cobertura.data
        
      } else {
        console.warn(`No se encontró la cobertura con el id: ${key}`);
      }
    }
    this.selectedCoberturas[`${key}_sumaAsegurada`] = numericValue;
  }
  
  
  
  
  
  
  getCoberturas(owner: string) {
    this.formatoService.getCoberturas(owner, this.aseguradora).subscribe((response) => {
        if (response[0]) {
          this.id = response[0]['id'];
            this.nombre = response[0]['nombre'];
            this.owner = response[0]['owner'];
          if (this.aseguradora === 'tumomento') {
                  this.paquetesUnicos =[]
                  this.coberturas = response
                  this.coberturas.forEach(cobertura => {
                    this.paquetesUnicos.push(cobertura.paquete)
                  });
                this.paquete=response[0]['paquete']
                this.selectedCoberturas = [];
                if (response[0] && typeof response[0]['data'] === 'string') {
                    const dataString = response[0]['data'].replace(/"\s*"/g, '", "');
                    this.coberturas = JSON.parse(dataString).map((numStr: string) => Number(numStr));
                } else if (response[0] && Array.isArray(response[0]['data'])) {
                    this.coberturas = response[0]['data'].map((numStr: string) => Number(numStr));
                } else {
                    this.coberturas = [];
                }

                if (this.coberturas.some(id => [1, 2, 3, 4].includes(id))) {
                    this.selectedCoberturas['gastos_medicos_ocupantes'] = true;
                    const idSeleccionado = this.coberturas.find(id => [1, 2, 3, 4].includes(id));
                    this.selectedCoberturas['gastos_medicos_ocupantes_id'] = idSeleccionado;
                }
                if (this.coberturas.some(id => [5,6,7].includes(id))) {
                    this.selectedCoberturas['responsabilidad_civil_personas'] = true;
                    const idSeleccionado = this.coberturas.find(id => [5,6,7].includes(id));
                    this.selectedCoberturas['responsabilidad_civil_personas_id'] = idSeleccionado;
                }
                if (this.coberturas.some(id => [23,24,25,26].includes(id))) {
                    this.selectedCoberturas['robo_total'] = true;
                    const idSeleccionado = this.coberturas.find(id => [23,24,25,26].includes(id));
                    this.selectedCoberturas['robo_total_id'] = idSeleccionado;
                }
                if (this.coberturas.some(id => [27,28,29].includes(id))) {
                  this.selectedCoberturas['robo_parcial'] = true;
                  const idSeleccionado = this.coberturas.find(id => [27,28,29].includes(id));
                  this.selectedCoberturas['robo_parcial_id'] = idSeleccionado;
                }
                if (this.coberturas.some(id => [33].includes(id))) {
                    this.selectedCoberturas['asistencia_legal'] = true;
                    const idSeleccionado = this.coberturas.find(id => [33].includes(id));
                    this.selectedCoberturas['asistencia_legal_id'] = idSeleccionado;
                }
                if (this.coberturas.some(id => [34].includes(id))) {
                    this.selectedCoberturas['asistencia_vial'] = true;
                    const idSeleccionado = this.coberturas.find(id => [34].includes(id));
                    this.selectedCoberturas['asistencia_vial_id'] = idSeleccionado;
                }
                if (this.coberturas.some(id => [35].includes(id))) {
                  this.selectedCoberturas['lazy_fee'] = true;
                  const idSeleccionado = this.coberturas.find(id => [35].includes(id));
                  this.selectedCoberturas['lazy_fee'] = idSeleccionado;
              }
                if (this.coberturas.some(id => [8,9,10].includes(id))) {
                    this.selectedCoberturas['responsabilidad_civil_muerte'] = true;
                    const idSeleccionado = this.coberturas.find(id => [8,9,10].includes(id));
                    this.selectedCoberturas['responsabilidad_civil_muerte_id'] = idSeleccionado;
                }
                if (this.coberturas.some(id => [30,31,32].includes(id))) {
                    this.selectedCoberturas['conductor'] = true;
                    const idSeleccionado = this.coberturas.find(id => [30,31,32].includes(id));
                    this.selectedCoberturas['conductor_id'] = idSeleccionado;
                }
                if (this.coberturas.some(id => [11,12,13].includes(id))) {
                    this.selectedCoberturas['responsabilidad_civil_bienes'] = true;
                    const idSeleccionado = this.coberturas.find(id => [11,12,13].includes(id));
                    this.selectedCoberturas['responsabilidad_civil_bienes_id'] = idSeleccionado;
                }
                const danosMaterialesId = this.coberturas.find(id => [17, 18, 19, 20, 21, 22].includes(id));
                if (danosMaterialesId) {
                    const cobertura = this.obtenerCoberturaPorId(danosMaterialesId);
                    this.selectedCoberturas['danos_materiales'] = true;
                    this.selectedCoberturas['danos_materiales_deductible'] = cobertura.deductible.toString();
                    this.selectedCoberturas['danos_materiales_opcion'] = cobertura.opcion_deducible_perdida_total.toString();
                    this.selectedCoberturas['danos_materiales_id'] = danosMaterialesId;
                }
                this.coberturas=response

            }
          if (this.aseguradora === 'qualitas') {
                  this.coberturas = response;
                      this.procesarCoberturas();
                      const paquetesUnicos = new Set();
                      const coberturasPorPaquete = {};
                      this.coberturas.forEach((cobertura) => {
                          const cobID = cobertura.noCobertura;
                          const paquete = cobertura.paquete;
                          paquetesUnicos.add(paquete);
                          if (!coberturasPorPaquete[paquete]) {
                              coberturasPorPaquete[paquete] = []; 
                          }
                          coberturasPorPaquete[paquete].push(cobertura);
                          this.selectedCoberturas[cobID] = cobertura.noCobertura;
                          this.selectedCoberturas[cobID + '_id'] = cobertura.id;
                          this.selectedCoberturas[cobID + '_nombre'] = cobertura.nombre;
                          this.selectedCoberturas[cobID + '_sumaAsegurada'] = cobertura.sumaAsegurada;
                          const deducible =  cobertura.deducible
                          this.selectedCoberturas[cobID + '_deducible'] = deducible;                
                      });
                      this.paquetesUnicos = Array.from(new Set(this.coberturas.map(cobertura => cobertura.paquete))); 
                      this.selectedCoberturas['coberturasPorPaquete'] = coberturasPorPaquete;
                      this.coberturasPorPaquete = coberturasPorPaquete 
                      this.cdRef.detectChanges();
                  


                }  
                  if (this.aseguradora === 'chubb') {
                    this.coberturas = response;
                    this.procesarCoberturas();
                    const paquetesUnicos = new Set();
                    const coberturasPorPaquete = {};
                    this.coberturas.forEach((cobertura) => {
                        const cobID = cobertura.cobID;
                        const paquete = cobertura.paquete;
                        paquetesUnicos.add(paquete);
                        if (!coberturasPorPaquete[paquete]) {
                            coberturasPorPaquete[paquete] = []; 
                        }
                        coberturasPorPaquete[paquete].push(cobertura);
                        this.selectedCoberturas[cobID] = cobID;
                        this.selectedCoberturas[cobID + '_id'] = cobertura.id;
                        this.selectedCoberturas[cobID + '_nombre'] = cobertura.nombre;
                        this.selectedCoberturas[cobID + '_sumaAsegurada'] = cobertura.sumaAsegurada;
                        const deducible = (cobertura.deduciblePorcentaje ?? cobertura.deducibleAdicional) * 100;
                        this.selectedCoberturas[cobID + '_deducible'] = deducible;                
                    });
                    this.paquetesUnicos = Array.from(paquetesUnicos);
                    this.selectedCoberturas['coberturasPorPaquete'] = coberturasPorPaquete;
                    this.coberturasPorPaquete = coberturasPorPaquete 
                
                } if (this.aseguradora == 'gnp' || this.aseguradora ==='ps'){
                  this.paquetesUnicos =[]
                  this.coberturas = response
                  this.coberturas.forEach(cobertura => {
                    this.paquetesUnicos.push(cobertura.paquete)
                  });
                }
                
                
                

                    if (this.aseguradora === 'ana') {
                      this.coberturas = response;
                      this.procesarCoberturas();
                      const paquetesUnicos = new Set();
                      const coberturasPorPaquete = {};
                      this.coberturas.forEach((cobertura) => {
                          const cobID = cobertura.cobID;
                          const paquete = cobertura.paquete;
                          paquetesUnicos.add(paquete);
                          if (!coberturasPorPaquete[paquete]) {
                              coberturasPorPaquete[paquete] = []; 
                          }
                          coberturasPorPaquete[paquete].push(cobertura);
                          this.selectedCoberturas[cobID] = cobID;
                          this.selectedCoberturas[cobID + '_id'] = cobertura.id;
                          this.selectedCoberturas[cobID + '_nombre'] = cobertura.nombre;
                          this.selectedCoberturas[cobID + '_sumaAsegurada'] = cobertura.sumaAsegurada;
                          const deducible =  cobertura.deducible
                          this.selectedCoberturas[cobID + '_deducible'] = deducible;                
                      });
                      this.paquetesUnicos = Array.from(new Set(this.coberturas.map(cobertura => cobertura.paquete))); 
                      this.selectedCoberturas['coberturasPorPaquete'] = coberturasPorPaquete;
                      this.coberturasPorPaquete = coberturasPorPaquete 
                      this.cdRef.detectChanges();
                  
                  }
              
              
                 
                
            
        }
    }, (e) => {
        this.coberturas = [];
        
    });
  }
  parseXMLToCoberturas(xmlString: string): any[] {
    const parser = new DOMParser();
  
    
    if (this.aseguradora === 'gnp') {
      const xmlDoc = parser.parseFromString(xmlString, 'application/xml');
      const coberturas = xmlDoc.getElementsByTagName('COBERTURA');
      const coberturaArray: any[] = [];
      Array.from(coberturas).forEach(cobertura => {
        const id = cobertura.getElementsByTagName('CVE_COBERTURA')[0]?.textContent || '';
        const nombre = cobertura.getElementsByTagName('NOMBRE')[0]?.textContent || '';
        const deducible = cobertura.getElementsByTagName('DEDUCIBLE')[0]?.textContent || '';
        const sumaAsegurada = cobertura.getElementsByTagName('SUMA_ASEGURADA')[0]?.textContent || '';
        coberturaArray.push({
          id,
          nombre,
          deducible: Number(deducible) || 0,
          sumaAsegurada: Number(sumaAsegurada) || 0,
        });
        this.sumaAsegurada[id] = parseInt(sumaAsegurada);
        this.deducibleGNP[id] = parseInt(deducible);
      });
      return coberturaArray;
    }
  
    if (this.aseguradora === 'ps') {
      const wrappedXMLString = `<root>${xmlString}</root>`;
      const xmlDoc = parser.parseFromString(wrappedXMLString, 'application/xml');
      const coberturas = xmlDoc.getElementsByTagName('coberturas');
      const coberturaArray: any[] = [];
  
      if (coberturas.length === 0) {
        console.error('No se encontraron coberturas en el XML para Primero Seguros');
      }

  
      Array.from(coberturas).forEach(cobertura => {
        const id = cobertura.getElementsByTagName('id')[0]?.textContent?.trim() || '';
        const sumaAsegurada = cobertura.getElementsByTagName('suma_asegurada')[0]?.textContent?.trim() || '0';
        const deducible = cobertura.getElementsByTagName('deducible')[0]?.textContent?.trim() || '0';
        const data =xmlString
        coberturaArray.push({
          id,
          sumaAsegurada: Number(sumaAsegurada),
          deducible: Number(deducible),
          
        });
  
        this.sumaAsegurada[id] = parseInt(sumaAsegurada);
        this.deducibleGNP[id] = parseInt(deducible);
      });
  
      return coberturaArray;
    }
  
    return [];
  }
  

  changePaquete() {
    if (this.aseguradora ==='chubb' ){
      this.selectedCoberturas = {}
      this.cdRef.detectChanges();
      this.coberturas = this.coberturasPorPaquete[this.paquete];
      if (this.coberturas) {
        this.coberturas.forEach((cobertura) => {
              const cobID = cobertura.cobID;
              this.selectedCoberturas[cobID] = cobID;
              this.selectedCoberturas[cobID + '_id'] = cobertura.id;
              this.selectedCoberturas[cobID + '_nombre'] = cobertura.nombre;
              this.selectedCoberturas[cobID + '_sumaAsegurada'] = cobertura.sumaAsegurada;
              const deducible = (cobertura.deduciblePorcentaje ?? cobertura.deducibleAdicional) * 100;
              this.selectedCoberturas[cobID + '_deducible'] = deducible;
          });
      } else {
          this.selectedCoberturas = {}; 
      }}
      if (this.aseguradora ==='gnp'){
        const paqueteSeleccionado = this.paquete; 
        const paqueteData = this.coberturas.find((cobertura) => cobertura.paquete === paqueteSeleccionado);
        if (paqueteData && paqueteData.data) {
          this.selectedCoberturas = this.parseXMLToCoberturas(paqueteData.data);
          this.id = paqueteData.id
        } else {
          this.selectedCoberturas = []; 
        }
      }
      if (this.aseguradora ==='ps'){
        this.selectedCoberturas =[]
        const paqueteSeleccionado = this.paquete; 
        const paqueteData = this.coberturas.find((cobertura) => cobertura.paquete === paqueteSeleccionado);
        if (paqueteData && paqueteData.data) {
          const data :string = '<coberturas>' + paqueteData.data
          this.coberturasPS = this.parseXMLToCoberturas(data);
          this.id = paqueteData.id
          if (this.coberturas) {
            this.coberturasPS.forEach((cobertura) => {
                  const cobID = cobertura.id;
                  this.selectedCoberturas[cobID] = cobID;
                  this.selectedCoberturas[cobID + '_id'] = cobertura.id;
                  this.selectedCoberturas[cobID + '_nombre'] = cobertura.nombre;
                  this.selectedCoberturas[cobID + '_sumaAsegurada'] = cobertura.sumaAsegurada;
                  this.selectedCoberturas[cobID + '_deducible'] = cobertura.deducible;
              });
              


          } else {
              this.selectedCoberturas = {}; 
          }
        } else {
          this.selectedCoberturas = []; 
        }
        
      }


      if (this.aseguradora ==='tumomento'){
          this.selectedCoberturas = {};
          const paqueteFiltrado = this.coberturas.filter((cobertura: any) => cobertura.paquete === this.paquete);
          this.id = paqueteFiltrado[0].id
          const dataString = paqueteFiltrado[0]['data'].replace(/"\s*"/g, '", "');
          const coberturas = JSON.parse(dataString).map((numStr: string) => Number(numStr));
          
          if (coberturas.some(id => [1, 2, 3, 4].includes(id))) {
              this.selectedCoberturas['gastos_medicos_ocupantes'] = true;
              this.selectedCoberturas['gastos_medicos_ocupantes_id'] = coberturas.find(id => [1, 2, 3, 4].includes(id));
          }
          if (coberturas.some(id => [5, 6, 7].includes(id))) {
              this.selectedCoberturas['responsabilidad_civil_personas'] = true;
              this.selectedCoberturas['responsabilidad_civil_personas_id'] = coberturas.find(id => [5, 6, 7].includes(id));
          }
          if (coberturas.some(id => [23,24,25,26].includes(id))) {
              this.selectedCoberturas['robo_total'] = true;
              const idSeleccionado = coberturas.find(id => [23,24,25,26].includes(id));
              this.selectedCoberturas['robo_total_id'] = idSeleccionado;
          }
          if (coberturas.some(id => [27,28,29].includes(id))) {
            this.selectedCoberturas['robo_parcial'] = true;
            const idSeleccionado = coberturas.find(id => [27,28,29].includes(id));
            this.selectedCoberturas['robo_parcial_id'] = idSeleccionado;
          }
          if (coberturas.some(id => [33].includes(id))) {
              this.selectedCoberturas['asistencia_legal'] = true;
              const idSeleccionado = coberturas.find(id => [33].includes(id));
              this.selectedCoberturas['asistencia_legal_id'] = idSeleccionado;
          }
          if (coberturas.some(id => [34].includes(id))) {
              this.selectedCoberturas['asistencia_vial'] = true;
              const idSeleccionado = coberturas.find(id => [34].includes(id));
              this.selectedCoberturas['asistencia_vial_id'] = idSeleccionado;
          }
          if (coberturas.some(id => [35].includes(id))) {
            this.selectedCoberturas['lazy_fee'] = true;
            const idSeleccionado = coberturas.find(id => [35].includes(id));
            this.selectedCoberturas['lazy_fee'] = idSeleccionado;
          }
          if (coberturas.some(id => [8,9,10].includes(id))) {
              this.selectedCoberturas['responsabilidad_civil_muerte'] = true;
              const idSeleccionado = coberturas.find(id => [8,9,10].includes(id));
              this.selectedCoberturas['responsabilidad_civil_muerte_id'] = idSeleccionado;
          }
          if (coberturas.some(id => [30,31,32].includes(id))) {
              this.selectedCoberturas['conductor'] = true;
              const idSeleccionado = coberturas.find(id => [30,31,32].includes(id));
              this.selectedCoberturas['conductor_id'] = idSeleccionado;
          }
          if (coberturas.some(id => [11,12,13].includes(id))) {
              this.selectedCoberturas['responsabilidad_civil_bienes'] = true;
              const idSeleccionado = coberturas.find(id => [11,12,13].includes(id));
              this.selectedCoberturas['responsabilidad_civil_bienes_id'] = idSeleccionado;
          }          
          const danosMaterialesId = coberturas.find(id => [17, 18, 19, 20, 21, 22].includes(id));
          if (danosMaterialesId) {
              const cobertura = this.obtenerCoberturaPorId(danosMaterialesId);
              this.selectedCoberturas['danos_materiales'] = true;
              this.selectedCoberturas['danos_materiales_deductible'] = cobertura.deductible.toString();
              this.selectedCoberturas['danos_materiales_opcion'] = cobertura.opcion_deducible_perdida_total.toString();
              this.selectedCoberturas['danos_materiales_id'] = danosMaterialesId;
          }
      }

      if (this.aseguradora ==='ana' ){
        this.selectedCoberturas = {}
        this.cdRef.detectChanges();
        this.coberturas = this.coberturasPorPaquete[this.paquete];
        if (this.coberturas) {
          this.coberturas.forEach((cobertura) => {
                const cobID = cobertura.cobID;
                this.selectedCoberturas[cobID] = cobID;
                this.selectedCoberturas[cobID + '_id'] = cobertura.id;
                this.selectedCoberturas[cobID + '_nombre'] = cobertura.nombre;
                this.selectedCoberturas[cobID + '_sumaAsegurada'] = cobertura.sumaAsegurada;
                const deducible =  cobertura.deducible;
                this.selectedCoberturas[cobID + '_deducible'] = deducible;
            });
        } else {
            this.selectedCoberturas = {}; 
        }
      }
      if (this.aseguradora ==='qualitas' ){
        this.selectedCoberturas = {}
        
        this.cdRef.detectChanges();
        this.coberturas = this.coberturasPorPaquete[this.paquete];
        if (this.coberturas) {
          this.coberturas.forEach((cobertura) => {
                const cobID = cobertura.noCobertura;
                this.selectedCoberturas[cobID] = cobertura.noCobertura;
                this.selectedCoberturas[cobID + '_id'] = cobertura.id;
                this.selectedCoberturas[cobID + '_nombre'] = cobertura.nombre;
                this.selectedCoberturas[cobID + '_sumaAsegurada'] = cobertura.sumaAsegurada;
                const deducible =  cobertura.deducible;
                this.selectedCoberturas[cobID + '_deducible'] = deducible;
            });
        } else {
            this.selectedCoberturas = {}; 
        }
      }
        
        
  }





  formatVisualSumaAsegurada(value: number | null | undefined): string {
    if (value == null || value === 0) return ''; 
    return value.toLocaleString('en-US'); 
  }

  getKeys(obj: any): string[] {
    return Object.keys(obj);
  }

  onCheckboxChange(key: string, id: number): void {
    const isChecked = this.selectedCoberturas[key];  
    if (isChecked) {
      this.selectedCoberturas[`${key}_id`] = id;
    } else {
      delete this.selectedCoberturas[`${key}_id`];
    }
  }

  onSumaAseguradaChange(value: any, key: string): void {
    const nuevoValor = Number(value); 
    this.selectedCoberturas[`${key}_sumaAsegurada`] = nuevoValor; 
    const cobertura = this.coberturas.find(c => c.noCobertura === parseInt(key));
    if (cobertura) {
        cobertura.sumaAsegurada = nuevoValor;
        cobertura.data = this.generarXML(cobertura); 
    }
  }

  get coberturasActuales(): any {
    if (this.aseguradora === 'qualitas') {
      return this.COBERTURAS_QUALITAS;
    } else if (this.aseguradora === 'gnp') {
      return this.COBERTURAS_GNP;
    } else if (this.aseguradora === 'chubb') {
      return this.COBERTURAS_CHUBB;
    } else if (this.aseguradora === 'ana') {
      return this.COBERTURAS_ANA;
    }else if (this.aseguradora === 'hdi') {
      return this.COBERTURAS_HDI;
    }else if (this.aseguradora === 'ps') {
      return this.COBERTURAS_PS;
    }
    else {
      return []; 
    }
  }


  mostrarCoberturas(): boolean {
    return ['qualitas', 'chubb', 'gnp','ana','hdi','ps'].includes(this.aseguradora);
  }

  onDeducibleChange(value: string, key: string): void {
    const nuevoDeducible = Number(value);
    
    this.selectedCoberturas[`${key}_deducible`] = nuevoDeducible;
  
    if (this.aseguradora === 'qualitas') {
      const cobertura = this.coberturas.find(c => c.noCobertura === parseInt(key));
      if (cobertura) {
        cobertura.deducible = nuevoDeducible;
        cobertura.data = this.generarXML(cobertura);
      }
    } 
  
    if ( this.aseguradora ==='ana' || this.aseguradora ==='hdi') {
      const cobertura = this.coberturas.find(c => c.cobID === parseInt(key));
      if (cobertura) {
        cobertura.deducible = nuevoDeducible;
        cobertura.data = this.generarXML(cobertura);
      }
      
    }
    if ( this.aseguradora ==='ana') {
      const cobertura = this.coberturas.find(c => c.noCobertura === parseInt(key));
      if (cobertura) {
        cobertura.deducible = nuevoDeducible;
        cobertura.data = this.generarXML(cobertura);
      }
    }
    if ( this.aseguradora ==='chubb') {
      const cobertura = this.coberturas.find(c => c.cobID === parseInt(key));
      if (cobertura) {
        cobertura.deducible = nuevoDeducible;
        cobertura.data = this.generarXML(cobertura);
      }
      
    }
  
    if (this.aseguradora === 'gnp') {
      const cobertura = this.coberturasFiltradas.find(c => c.id === key);
      if (cobertura) {
        cobertura.deducible = nuevoDeducible;
        cobertura.data = this.generarXML(cobertura);
      }
    }
    if (this.aseguradora === 'ps') {
      const cobertura = this.coberturasPS.find(c => c.id === key);
      if (cobertura) {
        cobertura.deducible = nuevoDeducible;
        cobertura.data = this.generarXML(cobertura);
        
        
              
      }

  
      
    }
  }
  

  onCheckboxChangeQualitas(key: string, _id: any): void {
    const isChecked = this.selectedCoberturas[key];
    if (isChecked) {
      this.crearCobertura(key, _id);
    } else {
      if(this.aseguradora ==='hdi'){
      }
    if (this.aseguradora === 'qualitas') {
      const cobertura = this.coberturas.find(c => c.noCobertura === parseInt(key));
      if (cobertura && cobertura.id) {
        this.formatoService.deleteCoberturas(cobertura.id, this.aseguradora)
          .subscribe(
            (response: any) => {
              this.coberturas = this.coberturas.filter(c => c.noCobertura !== parseInt(key));
            },
            (error: any) => {
              console.error("Error al borrar cobertura:", error);
            }
          );
        } 
        else {
          this.coberturas = this.coberturas.filter(c => c.noCobertura !== parseInt(key));
        }
      }
      if (this.aseguradora === 'chubb' || this.aseguradora ==='ana') {
        const cobertura = this.coberturas.find(c => 
          c.cobID === parseInt(key) && c.paquete === this.paquete
        );
        if (cobertura && cobertura.id) {
          this.formatoService.deleteCoberturas(cobertura.id, this.aseguradora)
            .subscribe(
              (response: any) => {
                this.coberturas = this.coberturas.filter(c => 
                  c.cobID !== parseInt(key) || c.paquete !== this.paquete
                );
              },
              (error: any) => {
                console.error("Error al borrar cobertura:", error);
              }
            );
        } else {
          this.coberturas = this.coberturas.filter(c => 
            c.cobID !== parseInt(key) || c.paquete !== this.paquete
          );
        }
      }
    }
  }



  crearCobertura(key: string, _id: any = null): void {
    const deducible = this.selectedCoberturas[`${key}_deducible`] || 0;
    const sumaAsegurada = this.selectedCoberturas[`${key}_sumaAsegurada`] || 0;
    const id = this.selectedCoberturas[`${key}_id`] || null;
    if (this.aseguradora =='qualitas'){
      const seleccion = {
          noCobertura: parseInt(key),
          sumaAsegurada: sumaAsegurada,
          deducible: deducible,
          id: this.COBERTURAS_QUALITAS[_id] || null,
          nombre: _id || '',
          data: '' ,
          paquete: this.paquete || "Amplia",
          owner: this.owner
      }
      seleccion.data = this.generarXML(seleccion); 
      const coberturaExistente = this.coberturas.find(c => c.noCobertura === parseInt(key));
      if (!coberturaExistente) {
        this.coberturas.push(seleccion);

      } else {
        Object.assign(coberturaExistente, seleccion);
    }
    } if (this.aseguradora == 'chubb'){
      const seleccion = {
        cobID: parseInt(key),
        sumaAsegurada: sumaAsegurada,
        deducible: deducible,
        id: this.COBERTURAS_CHUBB[_id] || null,
        nombre: _id || '',
        data: '' ,
        paquete: this.paquete ,
        owner: this.owner
      }
      
      seleccion.data = this.generarXML(seleccion); 
      const coberturaExistente = this.coberturas.find(c => c.cobID === parseInt(key));
      if (!coberturaExistente) {
        this.coberturas.push(seleccion);
    } else {
        Object.assign(coberturaExistente, seleccion);
      }
    }if (this.aseguradora == 'ana'){
      const seleccion = {
        cobID: parseInt(key),
        sumaAsegurada: sumaAsegurada,
        deducible: deducible,
        id: this.COBERTURAS_ANA[_id] || null,
        nombre: _id || '',
        data: '' ,
        paquete: this.paquete ,
        owner: this.owner
      }
      
      seleccion.data = this.generarXML(seleccion); 
      const coberturaExistente = this.coberturas.find(c => c.noCobertura === parseInt(key));
      if (!coberturaExistente) {
        this.coberturas.push(seleccion);
    } else {
        Object.assign(coberturaExistente, seleccion);
      }
    }if (this.aseguradora ==='hdi'){
7
    }
  }

  generarXML(cobertura: any): string {
    if (this.aseguradora === 'qualitas' && cobertura.noCobertura == 47) {
      return `<Coberturas NoCobertura="${cobertura.noCobertura}">
                <SumaAsegurada>${cobertura.sumaAsegurada}</SumaAsegurada>
                <TipoSuma>14</TipoSuma>
                <Deducible>${cobertura.deducible}</Deducible>
                <Prima>0.0</Prima>
              </Coberturas>`;
    } else if (this.aseguradora === 'qualitas') {
      return `<Coberturas NoCobertura="${cobertura.noCobertura}">
                <SumaAsegurada>${cobertura.sumaAsegurada}</SumaAsegurada>
                <TipoSuma>0</TipoSuma>
                <Deducible>${cobertura.deducible}</Deducible>
                <Prima>0.0</Prima>
              </Coberturas>`;
    }
    if (this.aseguradora === 'ps') {
      for (const key in this.selectedCoberturas) {
        if (this.selectedCoberturas.hasOwnProperty(key) && !key.includes('_')) {
          const id = this.selectedCoberturas[key];
          const deducible = this.selectedCoberturas[`${key}_deducible`] || 0;
          const sumaAsegurada = this.selectedCoberturas[`${key}_sumaAsegurada`] || 0;
    
          this.XML += `
            <coberturas>
              <id>${id}</id>
              <suma_asegurada>${sumaAsegurada}</suma_asegurada>
              <deducible>${deducible}</deducible>
            </coberturas>`;
        }
      }
      return this.XML
    }

    if (this.aseguradora === 'chubb') {
      const deducibleDividido = (cobertura.deducible ?? '') / 100;
      return `<COB>
                <COBID>${cobertura.cobID}</COBID>
                <SUMAASEG>${cobertura.sumaAsegurada}</SUMAASEG>
                <DADIC>${cobertura.deducibleAdicional ?? ''}</DADIC>
                <DPCT>${deducibleDividido ?? ''}</DPCT>
              </COB>`;
    }

    if (this.aseguradora === 'gnp') {
      return `<COBERTURA>
                <CVE_COBERTURA>${cobertura.id}</CVE_COBERTURA>
                <DEDUCIBLE>${cobertura.deducible || ''}</DEDUCIBLE>
                <NOMBRE>${cobertura.nombre.toUpperCase()}</NOMBRE>
                <SUMA_ASEGURADA>${cobertura.sumaAsegurada || ''}</SUMA_ASEGURADA>}
              </COBERTURA>`;
    }
    if (this.aseguradora === 'ana') {
      if (cobertura.cobID =='10'){
        return `<cobertura id="${cobertura.cobID}" 
        desc="${cobertura.desc || ''}" 
        sa="${cobertura.sumaAsegurada || ''}" 
        tipo="${'B'}" 
        ded="${cobertura.deducible || ''}" 
        pma="${cobertura.pma || ''}"/>`;

      } else{
        return `<cobertura id="${cobertura.cobID}" 
        desc="${cobertura.desc || ''}" 
        sa="${cobertura.sumaAsegurada || ''}" 
        tipo="${cobertura.tipo || ''}" 
        ded="${cobertura.deducible || ''}" 
        pma="${cobertura.pma || ''}"/>`;}
    }
    if (this.aseguradora === 'hdi') {
      return `{
      "Regla": ${cobertura.regla},
      "Clave": ${cobertura.cobID},
      "Descripcion": "${cobertura.nombre}",
      "SumaAsegurada": ${cobertura.sumaAsegurada || 0},
      "Deducible": ${cobertura.deducible || 0},
      "ProveedorAsistencia": ${cobertura.proveedorAsistencia},
      "PrimaNeta": ${cobertura.primaNeta},
      "Calculada": "${cobertura.calculada}",
      
      }`;
  }
    

    return '';
  }




  getSumaAsegurada(key: string): number {
    const cobertura = this.coberturas.find(c => c.noCobertura === Number(key));
    return cobertura ? cobertura.sumaAsegurada : 0;
  }
  
  
  formatNumber(value: number | string): string {
    if (!value) return ''; 
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','); 
  }

  removeCommas(value: string): string {
    return value.replace(/,/g, ''); 
  }
  
  formatCurrency(cobertura: any): void {
    const formattedValue = this.formatNumber(cobertura.sumaAsegurada); 
    
    const inputElement = document.querySelector(`[data-id="${cobertura.id}"]`) as HTMLInputElement;
    if (inputElement) {
      inputElement.value = formattedValue;
    }
  }
  
  procesarCoberturas() {
    this.coberturas = this.coberturas.map((cobertura: { data: any }) => {
      let cobID: string | null = null;
      let sumaAsegurada: string | null = null;
      let deducible: string | null = null;
      let deduciblePorcentaje: string | null = null;
      let deducibleAdicional: string | null = null;
      let tipoSuma: string | null = null;
      let prima: string | null = null;
      let noCobertura: string | null = null;
      let nombre: string | null = null;
      
      if (this.aseguradora === 'qualitas') {
        const dataXML = cobertura.data;
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(dataXML, 'text/xml');
  
        sumaAsegurada = this.obtenerValorNodo(xmlDoc, 'SumaAsegurada');
        tipoSuma = this.obtenerValorNodo(xmlDoc, 'TipoSuma');
        deducible = this.obtenerValorNodo(xmlDoc, 'Deducible');
        prima = this.obtenerValorNodo(xmlDoc, 'Prima');
        noCobertura = xmlDoc.getElementsByTagName('Coberturas')[0]?.getAttribute('NoCobertura');
      } 
      else if (this.aseguradora === 'chubb') {
        const dataXML = cobertura.data;
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(dataXML, 'text/xml');
  
        cobID = this.obtenerValorNodo(xmlDoc, 'COBID');
        sumaAsegurada = this.obtenerValorNodo(xmlDoc, 'SUMAASEG');
        deducibleAdicional = this.obtenerValorNodo(xmlDoc, 'DADIC');
        deduciblePorcentaje = this.obtenerValorNodo(xmlDoc, 'DPCT');
      }
      else if (this.aseguradora === 'ana') {
        const dataXML = cobertura.data;
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(dataXML, 'text/xml');
        
        const coberturaNode = xmlDoc.getElementsByTagName('cobertura')[0];
        if (coberturaNode) {
          cobID = coberturaNode.getAttribute('id');
          sumaAsegurada = coberturaNode.getAttribute('sa');
          deducible = coberturaNode.getAttribute('ded'); 
          nombre = coberturaNode.getAttribute('desc');
        }
      }
      else if (this.aseguradora === 'gnp') {
        const dataXML = cobertura.data;
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(dataXML, 'text/xml');
  
        const coberturaNode = xmlDoc.getElementsByTagName('COBERTURA');
        return Array.from(coberturaNode).map((node) => {
          const idCobertura = node.getElementsByTagName('CVE_COBERTURA')[0]?.textContent || '';
          const deducible = node.getElementsByTagName('DEDUCIBLE')[0]?.textContent || '-';
          const nombre = node.getElementsByTagName('NOMBRE')[0]?.textContent || 'Cobertura desconocida';
          const sumaAsegurada = node.getElementsByTagName('SUMA_ASEGURADA')[0]?.textContent || '-';
  
          return {
            cobID: idCobertura,
            sumaAsegurada: sumaAsegurada ? Number(sumaAsegurada) : null,
            deducible: deducible ? Number(deducible) : null,
            nombre: nombre,
          };
        });
      } 
      else if (this.aseguradora === 'hdi') {
        const dataJSON = JSON.parse(cobertura.data);
        const paquete = cobertura['paquete'];
        const id = cobertura['id'];
        const tipoCobertura = cobertura['nombre'];
        const regla = cobertura['regla'];
        return dataJSON.Cobertura.map((cob: any) => ({
          cobID: cob.Clave,
          sumaAsegurada: cob.SumaAsegurada ? Number(cob.SumaAsegurada) : null,
          deducible: cob.Deducible ? Number(cob.Deducible) : null,
          prima: cob.PrimaNeta ? Number(cob.PrimaNeta) : null,
          nombre: cob.Descripcion,
          paquete,
          id,
          tipoCobertura,
          regla: cob.Regla,
          proveedorAsistencia: cob.ProveedorAsistencia,
          primaNeta: cob.PrimaNeta,
          calculada: cob.Calculada
        }));
      }
      else if (this.aseguradora === 'ps') {
        const dataXML = cobertura.data;
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(dataXML, 'text/xml');

        const coberturaNodes = xmlDoc.getElementsByTagName('coberturas');
        return Array.from(coberturaNodes).map((node) => {
          const cobID = node.getElementsByTagName('id')[0]?.textContent || '';
          const sumaAsegurada = node.getElementsByTagName('suma_asegurada')[0]?.textContent || '0';
          const deducible = node.getElementsByTagName('deducible')[0]?.textContent || '0';

          return {
            cobID,
            sumaAsegurada: Number(sumaAsegurada),
            deducible: Number(deducible),
          };
        });
      }
  
      return {
        ...cobertura,
        cobID: cobID ? Number(cobID) : null,
        sumaAsegurada: sumaAsegurada ? Number(sumaAsegurada) : null,
        tipoSuma: tipoSuma ? Number(tipoSuma) : null,
        deducible: deducible ? Number(deducible) : null,
        deduciblePorcentaje: deduciblePorcentaje ? Number(deduciblePorcentaje) : '',
        deducibleAdicional: deducibleAdicional || null,
        prima: prima ? Number(prima) : null,
        noCobertura: noCobertura ? Number(noCobertura) : null,
        nombre: nombre
      };
    }).flat();
  }


  
  obtenerValorNodo(xmlDoc: Document, tagName: string): string | null {
    const node = xmlDoc.getElementsByTagName(tagName)[0];
    return node ? node.textContent : null;
  }
 
  getKeyById(id: number): string | null {
    for (const key of Object.keys(this.agrupadasCoberturas)) {
      const variaciones = this.agrupadasCoberturas[key];
      if (variaciones.some(v => v.id === id)) {
        return key; 
      }
    }
    return null; 
  }
  

  changeEmpresa(){
    if(this.aseguradora !=='hdi' && this.owner){
      this.getCoberturas(this.owner);
      this.cobertura_seleccionada={};
    }
  }

  changeAseguradora(){
    if (this.owner && this.aseguradora){
      this.getCoberturas(this.owner);
      this.cobertura_seleccionada={};
    }
  }


  closeBtnClick() {
    this.modalService.dismissAll();
  }

  deleteCobertura(cobertura:any, index:number){
    Swal.fire({
      title: '¿Está seguro?',
      text: "Esta operación no podrá deshacerse",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, borrar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        this.formatoService.deleteCoberturas(cobertura.id, this.aseguradora).subscribe(() =>{

          this.coberturas.splice(index, 1);
        });
      }
    });
  }
  
  guardarCobertura() {
    if (this.aseguradora === 'tumomento' && this.coberturas[0]) {
      const coberturasSeleccionadas: string[] = Object.keys(this.selectedCoberturas)
        .filter(nombre => this.selectedCoberturas[nombre]) 
        .map(nombre => this.selectedCoberturas[nombre + '_id']?.toString()) 
        .filter(id => id !== undefined); 
      const dataStr: string = JSON.stringify(coberturasSeleccionadas);
      const coberturasObj = {
        data: dataStr,
        paquete: this.paquete,
        nombre: this.nombre,
        id: this.id,
        owner: this.owner
      };
      this.formatoService.updateCobertura(coberturasObj, this.aseguradora)
        .subscribe((response: any) => {
          this.closeBtnClick(); 
        });
    }else if (this.aseguradora === 'tumomento') {
      const coberturasSeleccionadas: string[] = Object.keys(this.selectedCoberturas)
        .filter(nombre => this.selectedCoberturas[nombre]) 
        .map(nombre => this.selectedCoberturas[nombre + '_id']?.toString()) 
        .filter(id => id !== undefined); 
        const dataStr: string = JSON.stringify(coberturasSeleccionadas);
        const coberturasObj = {
          data: dataStr,
          paquete: this.paquete,
          nombre: this.nombre,
          id: this.id,
          owner: this.owner
        };
        this.formatoService.createCoberturas(coberturasObj, this.aseguradora).subscribe((response:any) =>{
          this.coberturas.unshift(response)
          this.closeBtnClick();
        });
      }  
      if (this.aseguradora == 'qualitas') {
        this.coberturas.forEach((cobertura) => {
          const servicio = cobertura.id 
            ? this.formatoService.updateCobertura(cobertura, this.aseguradora)
            : this.formatoService.createCoberturas(cobertura, this.aseguradora);
          servicio.subscribe(
            (response: any) => {
            },
            (error: any) => {
              console.error('Error al enviar la cobertura:', error);
            }
          );
        });
      }if (this.aseguradora == 'chubb' || this.aseguradora =='ana' ) {
        this.coberturas.forEach((cobertura) => {
          const servicio = cobertura.id 
            ? this.formatoService.updateCobertura(cobertura, this.aseguradora)
            : this.formatoService.createCoberturas(cobertura, this.aseguradora);
          servicio.subscribe(
            (response: any) => {
            },
            (error: any) => {
              console.error('Error al enviar la cobertura:', error);
            }
          );
        });
      }
      if (this.aseguradora ==='gnp'){
        let coberturasXML = '<COBERTURAS> \n';

          for (let cobertura of this.selectedCoberturas) {
            
            const xmlCobertura = this.generarXML(cobertura);
            coberturasXML += xmlCobertura; 
          }
          coberturasXML += '\n </COBERTURAS>';
          const cobertura = {
            id:this.id,
            data :coberturasXML,
            nombre: this.paquete,
            owner : this.owner,
            paquete: this.paquete
          }

          this.formatoService.updateCobertura(cobertura, this.aseguradora).subscribe((response:any)=>{
            this.getCoberturas(this.owner)

          })
          this.paquete=''
      } if (this.aseguradora ==='hdi'){
        const coberturasAgrupadas: { [id: string]: any[] } = {};

    for (const key in this.selectedCoberturas) {
        if (this.selectedCoberturas.hasOwnProperty(key) && key.includes('_id')) {
            const id = this.selectedCoberturas[key];
            const clave = this.selectedCoberturas[key.replace('_id', '_clave')];
            const regla = this.selectedCoberturas[key.replace('_id', '_regla')];
            const descripcion = this.selectedCoberturas[key.replace('_id', '_descripcion')];
            const sumaAsegurada = this.selectedCoberturas[key.replace('_id', '_sumaAsegurada')];
            const deducible = this.selectedCoberturas[key.replace('_id', '_deducible')] ?? 0;
            this.tipoCobertura = this.selectedCoberturas[key.replace('_id', '_tipoCobertura')];

            if (!coberturasAgrupadas[id]) {
                coberturasAgrupadas[id] = [];
            }

            coberturasAgrupadas[id].push({
                Regla: regla,
                Clave: clave,
                Descripcion: descripcion,
                SumaAsegurada: sumaAsegurada || 0,
                Deducible: deducible,
                ProveedorAsistencia: 0, 
                PrimaNeta: 0,            
                Calculada: "True"
            });
        }
    }
    

    
    const objetosAgrupados = Object.keys(coberturasAgrupadas).map((id) => {
        
        return {
            data: JSON.stringify({
                Cobertura: coberturasAgrupadas[id]
            }, null, 3),  
            id: Number(id),
            nombre: this.tipoCobertura || '',
            owner: this.owner,
            paquete: this.paquete
        };
    });
    
    
    
      objetosAgrupados.forEach((cobertura) => {
        const servicio = cobertura.id 
          ? this.formatoService.updateCobertura(cobertura, this.aseguradora)
          : this.formatoService.createCoberturas(cobertura, this.aseguradora);
    
        servicio.subscribe(
          (response: any) => {
          },
          (error: any) => {
            console.error('Error al enviar la cobertura:', error);
          }
        );
        });

      } if (this.aseguradora ==='ps'){
        let xmlString
        for (const key in this.selectedCoberturas) {
          if (this.selectedCoberturas.hasOwnProperty(key) && !key.includes('_')) {
            const id = this.selectedCoberturas[key];
            const deducible = this.selectedCoberturas[`${key}_deducible`] || 0;
            const sumaAsegurada = this.selectedCoberturas[`${key}_sumaAsegurada`] || 0;
      
            // Generar el nodo de cobertura para PS
            xmlString += `
              <coberturas>
                <id>${id}</id>
                <suma_asegurada>${sumaAsegurada}</suma_asegurada>
                <deducible>${deducible}</deducible>
              </coberturas>`;
          }
        }
        const resultado = xmlString.replace('<coberturas>', '')
        const cobertura = {
          id:this.id,
          data :resultado,
          nombre: this.paquete,
          owner : this.owner,
          paquete: this.paquete
        }

        this.formatoService.updateCobertura(cobertura, this.aseguradora).subscribe((response:any)=>{
          this.getCoberturas(this.owner)

        })
                
        

      }
    Swal.fire('Éxito', 'Coberturas procesadas correctamente.', 'success');
  }

  guardarEmpresa(){
    this.formatoService.createEmpresa(this.nueva_empresa).subscribe((response:any) =>{
      this.empresas.unshift(response);
      this.nueva_empresa = '';
      this.closeBtnClick();
    });
    Swal.fire('Éxito', 'Empresa guardada exitosamente.', 'success');
  }

  modalCobertura(modalCobertura:any, cobertura:any){
    this.cobertura_seleccionada = cobertura;
    this.modalService.open(modalCobertura, {
      centered: true,
      backdrop: 'static',
      size:'lg'
    });
  }
  formatDeducible(deducible: string): string {
    if (!deducible || deducible === 'N/A') {
      return '-';
    }
    const deducibleNum = deducible.slice(-4); 
    const porcentaje = parseInt(deducibleNum, 10);
    
    return `${porcentaje} %`;
  }
  
  

  modalEmpresa(modalEmpresa:any){
    this.modalService.open(modalEmpresa, {
      centered: true,
      backdrop: 'static',
      size:'md'
    });
  }
  getResultadoCobertura(key: string, tipo: string) {
    const resultado = this.coberturasRespuesta.find(item => item.NoCobertura === key);
    return resultado ? resultado[tipo] : 'N/A'; 
  }
  formatResultado(resultado: any): string {
    return resultado === 'N/A' ? 0 : resultado;
  }
  



  }

  

