import { Component } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { RouteInfo } from './horizontal-sidebar.metadata';
import { HorizontalSidebarService } from './horizontal-sidebar.service';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { FeatherModule } from 'angular-feather';
import { AjustesProvider } from '../../services/ajustes';

@Component({
  selector: 'app-horizontal-sidebar',
  standalone: true,
  imports: [TranslateModule, CommonModule, RouterModule, FeatherModule],
  templateUrl: './horizontal-sidebar.component.html',
})
export class HorizontalSidebarComponent {

  showMenu = '';
  showSubMenu = '';
  public sidebarnavItems: RouteInfo[] = [];
  path = '';
  user;

  constructor(
    private menuServise: HorizontalSidebarService,
    private router: Router,
    private _ajustes: AjustesProvider
  ) {
    this.user = this._ajustes['sesionStorage']['user'];
    this.menuServise.items.subscribe((menuItems) => {
      this.sidebarnavItems = menuItems;
      for(let item of this.sidebarnavItems){       
        for(let sub of item['submenu']){          
          if(sub['submenu'].length > 0){
            for(let subsub of sub['submenu']){
              if(subsub['path'] == '/recibos/lista' || subsub['path'] == '/recibos/masivos' || subsub['path'] == '/recibos/smtp'){
                if(this.user['is_staff'] || this.user['is_superuser']){
                  subsub['extralink'] = false;
                  sub['extralink'] = false;
                }else{
                  subsub['extralink'] = true;
                  sub['extralink'] = true;
                }
              }else{
                subsub['extralink'] = this.user['is_staff']
              }
            }
          }else{
            if(sub['path'] == '/grupos' || sub['path'] == '/usuarios' || sub['path'] == '/administrador/comunicados' || sub ['path'] == '/administrador/interes' || sub ['path'] == '/administrador/formatos' || sub ['path'] == 'administrador/market' || sub ['path'] == 'ayuda/admin' || sub ['path'] == 'administrador/rh'){
              if(this.user['is_staff'] || this.user['is_superuser']){
                sub['extralink'] = false;
              }else{
                sub['extralink'] = true;
              }
            }else{
              sub['extralink'] = this.user['is_staff']
            }
          }          
        }
      }

      // Active menu
      this.sidebarnavItems.filter((m) =>
        m.submenu.filter((s) => {
          if (s.path === this.router.url) {
            this.path = m.title;
          }
        })
      );
      this.addExpandClass(this.path);
    });
  }

  addExpandClass(element: any) {
    if (element === this.showMenu) {
      this.showMenu = element;
    } else {
      this.showMenu = element;
    }
  }

  addActiveClass(element: any) {
    if (element === this.showSubMenu) {
      this.showSubMenu = element;
    } else {
      this.showSubMenu = element;
    }
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }
}
