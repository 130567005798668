<div class="auth-wrapper d-flex no-block justify-content-center align-items-center" style="background:url(../../assets/images/background/vivid-photo-blurred-color-wallpaper-background.jpg) no-repeat center center; background-size: cover;">
    <div>
        <div class="auth-box" style="border-radius: 15px;">
            <div id="loginform" [ngClass]="{'d-none': recoverform}">
                <div class="text-center">
                    <span class="db"><img src="assets/images/logo.jpg" style="display: block; height: 300px; margin: 0 auto; border-radius: 7px;" alt="logo" /></span>
                    <!-- <h5 class="font-medium mb-4">SoftMax</h5> -->
                </div>
                <div *ngIf="msg" class="alert alert-danger">{{ msg }}</div>
                <!-- Form -->
                <div class="row">
                    <div class="col-12">
                        <form class="form-horizontal mt-3" id="loginform">
                            <div class="input-group mb-3"> 
                                <span class="input-group-text" id="basic-addon1"><i class="ti-user"></i></span>
                                <input type="text" class="form-control form-control-lg" placeholder="Usuario/Correo" aria-label="Username"
                                aria-describedby="basic-addon1" #u1>
                            </div>
                            <div class="input-group mb-3"> 
                                <span class="input-group-text" id="basic-addon2"><i class="ti-pencil"></i></span>
                                <input type="password" class="form-control form-control-lg" placeholder="Contraseña" aria-label="Password"
                                aria-describedby="basic-addon1" #p2 id="password">
                                <div class="col-12 form-group mt-3">
                                    <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" (click)="showPassword()">
                                    <label class="form-check-label" for="flexCheckChecked">
                                        Mostrar contraseña
                                    </label>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="form-group text-center mt-3">
                                <div class="col-xs-12 pb-4">
                                    <button class="btn d-block w-100 btn-lg btn-dark" type="button" (click)="check(u1.value, p2.value)">Entrar</button>
                                </div>
                            </div>
                           
                            <div class="form-group row">
                                <div class="col-md-12">
                                    <div class="form-check">
                                        <a (click)="resetear_password()"  href="javascript:void(0)" id="to-recover" class="text-dark float-center"><i
                                        class="fa fa-lock me-1"></i> ¿Olvidaste tu contraseña?</a>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
           
        </div>
    </div>
</div>