import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';


@Injectable()
export class AjustesProvider {

  sesionStorage = {
    'token': '',
    'opcionSeleccionada': 1,
    'user': {
      'rol': '001',
      'id': 0
    }
  }

  constructor() {
  }

 
  borrarAjustes(){
    this.sesionStorage.token = '';
    this.sesionStorage.user = {
      "rol":"001",
      "id":0
    };
    this.guardarSesionStorage();
  }

  
  cargarSessionStorage(){
    let promesa = new Promise((resolve, reject)=>{
      if (sessionStorage.getItem('sesionStorage')){
        this.sesionStorage = JSON.parse(sessionStorage.getItem('sesionStorage') || '{}')
      resolve(true);
      }
    });
    return promesa    
  }

  guardarSesionStorage(){
    sessionStorage.setItem('sesionStorage', JSON.stringify(this.sesionStorage))
  }

}
