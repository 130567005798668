import { Component } from '@angular/core';
import { RouterModule, Router } from '@angular/router';
import { MyserviceService } from './../myservice.service';
import { CommonModule } from '@angular/common';
import { RestApiProvider } from '../services/api.service';
import { AjustesProvider } from '../services/ajustes';
import { ToastService } from '../component/toast/toast.service';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import Swal from 'sweetalert2'
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html'
})
export class LoginComponent {
  msg = '';
  email_a_resetear:string = '';
  constructor(
    private router: Router,
    private _rest_api: RestApiProvider,
    private _ajustes: AjustesProvider,
    private _toastr: ToastService
    ) {}

  loginform = true;
  recoverform = false;

  showRecoverForm() {
    this.loginform = !this.loginform;
    this.recoverform = !this.recoverform;
  }
  check(username: string, password: string) {
    if (!username || !password){
      this.msg = "El Usuario/Email y la contraseña son obligatorios.";
    }
    this._rest_api.login(username, password).then((response:any) => {
      this._ajustes.sesionStorage.token = response['token'];
      this._ajustes.sesionStorage.user = response['user'];
      this._ajustes.guardarSesionStorage();
      this.router.navigate(['/inicio']);
      setTimeout(() => {
        this._toastr.show('Inicio de sesión exitoso', {
          classname: 'bg-success text-light',
          delay: 10000,
        });
      }, 1000);
    }, error => {
      try{
        this._toastr.show(error['error']);
      } catch(e){
        console.log(e);
      }
      this.msg = error.error.error;
    }).catch(error => {
      try{
        this._toastr.show(error['error']);
      } catch(e){
        console.log(e);
      }
    })
  }

  resetear_password(){
    Swal.fire({
      title: 'Ingrese su correo electrónico',
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonText: 'Restablecer contraseña',
      showLoaderOnConfirm: true,
      preConfirm: (login) => {
        if(login == ''){
          Swal.showValidationMessage(
            "Error: Ingrese un email válido"
          )
          return 0;
        }
        return fetch(`${environment.IP_CONF}core/forgot-password/${login}`)
          .then(response => {
            console.log(response);
            
            if (response.status == 404) {
              throw new Error("El email ingresado no existe, asegurese de haber escrito correctamente y que el usuario no este desactivado por algún administrador.")
            }
            return response.status
          })
          .catch(error => {
            Swal.showValidationMessage(
              `${error}`
            )
          })
      },
      allowOutsideClick: () => !Swal.isLoading()
      }).then((result) => {
        console.log(result);
        if (result.value == 200) {
          Swal.fire({
            title: "Correo de restablecimiento de contraseña enviado, revise su bandeja de entrada para continuar con el proceso."
          })
        }
      })
  }

  showPassword(){
    let type:any = document.getElementById("password");
    if(type.type == "password"){
        type.type = "text";
    }else{
        type.type = "password";
    }
  }


  
}
