<!-- ============================================================== -->
<!-- toggle and nav items -->
<!-- ============================================================== -->
<ul class="navbar-nav float-start">
  <li class="nav-item d-none d-lg-block">
    <a
      (click)="toggleSidebar.emit()"
      class="nav-link sidebartoggler waves-effect waves-light"
      href="javascript:void(0)"
    >
      <i class="ti-menu"></i>
    </a>
  </li>
  <!-- ============================================================== -->
  <!-- Search -->
  <!-- ============================================================== -->
  <!-- <li class="nav-item search-box">
    <a
      (click)="showSearch = !showSearch"
      class="nav-link waves-effect waves-dark"
      href="javascript:void(0)"
    >
      <i class="ti-search"></i>
    </a>
    <form
      class="app-search position-absolute"
      [ngClass]="{ 'show-search': showSearch }"
    >
      <input
        type="text"
        class="form-control"
        placeholder="Search &amp; enter"
      />
      <a class="srh-btn" (click)="showSearch = !showSearch">
        <i class="ti-close"></i>
      </a>
    </form>
  </li> -->
</ul>

<!-- Navbar Header for horizontal only -->
<div class="navbar-header d-none d-lg-block">
  <!-- This is for the sidebar toggle which is visible on mobile only -->
  <a
    (click)="showMobileMenu = !showMobileMenu"
    class="nav-toggler waves-effect waves-light d-block d-lg-none"
    href="javascript:void(0)"
  >
    <i [ngClass]="showMobileMenu ? 'ti-close' : 'ti-menu'"></i>
  </a>
  <!-- ============================================================== -->
  <!-- Logo -->
  <!-- ============================================================== -->
  <a class="navbar-brand" href="/inicio">
    <!-- Logo icon -->
    <b class="logo-icon">
      <!--You can put here icon as well // <i class="wi wi-sunset"></i> //-->
      <!-- Dark Logo icon -->
      <img src="../../../assets/images/logo_horizontal-fotor-bg-remover-2024061815316.png" height="50" alt="homepage" class="dark-logo" />
      <!-- Light Logo icon -->
     
    </b>
    <!--End Logo icon -->
    <!-- Logo text -->
    <span class="logo-text">
      <!-- dark Logo text -->
      <!-- Light Logo text -->
    
    </span>
  </a>
  <!-- ============================================================== -->
  <!-- End Logo -->
  <!-- ============================================================== -->
  <!-- ============================================================== -->
  <!-- Toggle which is visible on mobile only -->
  <!-- ============================================================== -->
  <a
    class="topbartoggler d-block d-lg-none waves-effect waves-light"
    href="javascript:void(0)"
    (click)="isCollapsed = !isCollapsed"
    [attr.aria-expanded]="!isCollapsed"
    data-target="#navbarSupportedContent"
    aria-controls="navbarSupportedContent"
  >
    <i class="ti-more"></i>
  </a>
</div>

<!-- ============================================================== -->
<!-- Right side toggle and nav items -->
<!-- ============================================================== -->
<ul class="navbar-nav float-end">
  <!-- User profile and search -->
  <!-- ============================================================== -->
  <li class="nav-item" ngbDropdown placement="bottom-end">
    <a
      ngbDropdownToggle
      class="nav-link text-muted waves-effect waves-dark pro-pic"
      href="javascript:void(0)"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
    <!-- anterior -->
      <!-- <img
        *ngIf="user.userprofile"
        [src]="user.userprofile.avatar"
        alt="user"
        class="rounded-circle"
        width="31"
      /> -->
      <!-- nuevo -->
      <img
      *ngIf="user.userprofile"
      src="../../../assets/images/users/user.png"
      alt="user"
      class="rounded-circle"
      width="31"
      style="width: 30px;"
    />

      <img
        *ngIf="!user.userprofile"
        src="../../../assets/images/users/user.png"
        alt="user"
        class="rounded-circle"
        width="31"
      />
    </a>
    <div class="dropdown-menu-right user-dd" ngbDropdownMenu>
      <div class="d-flex no-block align-items-center p-3 mb-2">
        <div class="">
          <!-- anterior -->
          <!-- <img
            *ngIf="user.userprofile"
            [src]="user.userprofile.avatar"
            alt="user"
            class="rounded-circle"
            width="31"
          /> -->

          <img
          *ngIf="user.userprofile"
          src="../../../assets/images/users/user.png"
          alt="user"
          class="rounded-circle"
          width="31"
          style="width: 30px;"
        />

          <img
            *ngIf="!user.userprofile"
            src="../../../assets/images/users/user.png"
            alt="user"
            class="rounded-circle"
            width="31"
          />
        </div>
        <div class="ms-2">
          <!-- <h4 class="mb-0 fs-4">{{user.first_name}}</h4>
          <p class="text-muted mb-0 fs-2">{{user.email}}</p> -->
          <h4 class="mb-0 fs-4">Usuario</h4>
          <!-- <a
            href="javascript:void(0)"
            class="btn btn-danger btn-rounded btn-sm mt-1"
            >View Profile</a
          > -->
        </div>
      </div>
      <!-- <div class="dropdown-divider"></div>
      <a class="dropdown-item" href="javascript:void(0)">
        <i class="ti-settings me-1 ms-1"></i> Account Setting</a
      > -->
      <div class="dropdown-divider"></div>
      <a class="dropdown-item" href="javascript:void(0)" (click)="logout()">
        <i class="fa fa-power-off me-1 ms-1"></i> Cerrar sesión</a
      >
    </div>
  </li>
  <!-- ============================================================== -->
  <!-- User profile and search -->
  <!-- ============================================================== -->
</ul>
