import { Injectable } from '@angular/core';
import { Formato, Carpeta } from './inicio';
import { AjustesProvider } from '../services/ajustes';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment'
import { Observable } from 'rxjs';
import { Form } from '@angular/forms';


@Injectable()
export class FormatoService {
    public headers:any;
    public url:string;

    constructor(
        private _ajustes: AjustesProvider,
        private http: HttpClient,
      ) { 
        this._ajustes.cargarSessionStorage();
        this.url = environment.IP_CONF
        this.headers = { 
          'Content-Type': 'application/json', 
          'Authorization': 'Token '+ this._ajustes.sesionStorage.token
        }
      }
    


    
}
