import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from '@angular/router';
import { Observable } from 'rxjs';
import { AjustesProvider } from './services/ajustes';

@Injectable({
  providedIn: 'root'
})

export class AuthGuard implements CanActivate {

  constructor(
    private routes: Router,
    private _ajustes: AjustesProvider
    ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
    this._ajustes.cargarSessionStorage();
    if (this._ajustes.sesionStorage.token != null && this._ajustes.sesionStorage.token != '') {
      return true;
    } else {
      this.routes.navigate(['/login']);
      return false;
    }
  }
}
