import { RouteInfo } from './vertical-sidebar.metadata';

export const ROUTES: RouteInfo[] = [
  {
    path: '',
    title: 'Personal',
    icon: 'mdi mdi-dots-horizontal',
    class: 'nav-small-cap',
    extralink: true,
    label: '',
    labelClass: '',
    submenu: []
  },
  {
    path: '/inicio',
    title: 'Página principal',
    icon: 'home',
    class: '',
    extralink: false,
    label: '',
    labelClass: '',
    submenu: []
  },
  {
    path: '/grupos',
    title: 'Grupos',
    icon: 'icon-group',
    class: '',
    extralink: false,
    label: '',
    labelClass: '',
    submenu: []
  },
  {
    path: '/usuarios',
    title: 'Usuarios',
    icon: 'icon-user',
    class: '',
    extralink: false,
    label: '',
    labelClass: '',
    submenu: []
  },
  {
    path: '/tickets',
    title: 'Tickets',
    icon: 'icon-note',
    class: '',
    extralink: false,
    label: '',
    labelClass: '',
    submenu: []
  },
  {
    path: '/recibos',
    title: 'Recibos',
    icon: 'icon-paper-clip',
    class: '',
    extralink: false,
    label: '',
    labelClass: '',
    submenu: [
      {
        path: "/recibos/lista",
        title: "Lista de recibos",
        icon: "icon-note",
        class: "",
        label: "",
        labelClass: "",
        extralink: false,
        submenu: []
      },
      {
        path: "/recibos/masivos",
        title: "Envio de recibos",
        icon: "icon-note",
        class: "",
        label: "",
        labelClass: "",
        extralink: false,
        submenu: []
      },
    ]
  },
  {
    path: '/smtp',
    title: 'SMTP',
    icon: 'icon-envelope-open',
    class: '',
    extralink: false,
    label: '',
    labelClass: '',
    submenu: []
  },
  {
    path: '/creditos',
    title: 'Créditos',
    icon: 'icon-credit-card',
    class: '',
    extralink: false,
    label: '',
    labelClass: '',
    submenu: []
  },
  {
    path: '/viajes',
    title: 'Viajes',
    icon: 'icon-plane',
    class: '',
    extralink: false,
    label: '',
    labelClass: '',
    submenu: []
  },
  {
    path: '/market',
    title: 'Market Place',
    icon: 'mdi mdi-store',
    class: '',
    extralink: false,
    label: '',
    labelClass: '',
    submenu: []
  },
  {
    path: '/buzon',
    title: 'Mi buzón',
    icon: 'mdi mdi-mailbox',
    class: '',
    extralink: false,
    label: '',
    labelClass: '',
    submenu: []
  },
  {
    path: '/blog',
    title: 'Arctículos de interes',
    icon: 'mdi mdi-blogger',
    class: '',
    extralink: false,
    label: '',
    labelClass: '',
    submenu: []
  },
  // {
  //   path: "mlevel",
  //   title: "Catálogos",
  //   icon: "align-left",
  //   class: "has-arrow",
  //   label: "",
  //   labelClass: "",
  //   extralink: false,
  //   submenu: [
      // {
      //   path: "/estaciones",
      //   title: "Estaciones",
      //   icon: "icon-note",
      //   class: "",
      //   label: "",
      //   labelClass: "",
      //   extralink: false,
      //   submenu: [],
      // },
  //   ],
  // }
];
