
<div class="row">
  <div class="card card-body">
    <div class="col-12 ms-3">
        <div class="col-12 col-sm-6 mt-3" >
          <label class="mb-3">Aseguradora</label>
            <select [(ngModel)]="aseguradora" (change)="changeAseguradora()" class="form-control col-6 ">
                <option *ngFor="let aseguradora of aseguradoras" [value]="aseguradora">{{aseguradora}}</option>
            </select>
        </div>
        <div class="col-12 col-sm-6 mt-4">
          <label class="mb-3">Negocio</label>
            <select [(ngModel)]="owner" (change)="changeEmpresa()" class="form-control col-6 ">
                <option *ngFor="let empresa of empresas" [value]="empresa.nombre">{{empresa.nombre}}</option>
            </select>
        </div>
        <div *ngIf="aseguradora === 'chubb' || aseguradora ==='gnp'  || aseguradora ==='ana' || aseguradora ==='tumomento' || aseguradora ==='qualitas' || aseguradora ==='ps'"  class="col-12 col-sm-6 mt-4">
          <label class="mb-3">Paquete</label>
          <select  [(ngModel)]="paquete" (change)="changePaquete()" class="form-control col-6">
              <option value="" disabled selected>Seleccione un paquete</option>
              <option *ngFor="let paquet of paquetesUnicos" [value]="paquet">{{paquet}}</option>
          </select>
        </div>

        <div *ngIf="aseguradora ==='hdi'  " class="mt-4">
          <label *ngFor="let option of tipoOpciones" class="d-block mb-2">
            <input
                type="radio"
                name="tipo"
                [value]="option"
                (change)="onTipoChange(option)"
              />
              <span class="ml-2">{{ option }}</span>
          </label>
        </div>
      <div class="col-12 col-sm-6 mt-4">
        <button class="col-12 col-sm-5 offset-md-3 btn btn-dark text-white mb-3" style="margin: 0 10px 0 0;" (click)="modalEmpresa(empresaModal)"><i class="fa fa-plus-square me-2"></i>Nueva empresa</button>
        <button class="col-12 col-sm-5 offset-md-3 btn btn-dark text-white mb-3" style="margin: 0 0 0 10px;" (click)="modalCobertura(configModal, {})"><i class="fa fa-plus-square me-2"></i>Nueva cobertura</button>
      </div>
    </div>
    <div class="row">
      <div class="col-12 table-responsive">
        <table class="col-10 table table-sm align-middle">
          <!-- Cuando la aseguradora es 'tumomento' -->
          <thead *ngIf="aseguradora === 'tumomento'">
            <tr>
              <th>Selección</th>
              <th>Nombre de la Cobertura</th>
              <th>Suma Asegurada</th>
              <th>Deducible</th>
              <th>Opción Deducible Pérdida Total</th>
            </tr>
          </thead>
          <tbody *ngIf="aseguradora === 'tumomento'">
            <!-- Gastos Médicos Ocupantes -->
            <tr>
              <td>
                <input 
                  type="checkbox" 
                  [(ngModel)]="selectedCoberturas['gastos_medicos_ocupantes']" 
                />
              </td>
              <td>Gastos médicos ocupantes</td>
              <td>
                <select [(ngModel)]="selectedCoberturas['gastos_medicos_ocupantes_id']">
                  <option value="1">$300,000</option>
                  <option value="2">$350,000</option>
                  <option value="3">$400,000</option>
                  <option value="4">$500,000</option>
                </select>
              </td>
              <td>-</td>
              <td>-</td>
            </tr>
          
            <!-- Responsabilidad Civil Personas -->
            <tr>
              <td>
                <input 
                  type="checkbox" 
                  [(ngModel)]="selectedCoberturas['responsabilidad_civil_personas']" 
                />
              </td>
              <td>Responsabilidad Civil Personas</td>
              <td>
                <select [(ngModel)]="selectedCoberturas['responsabilidad_civil_personas_id']">
                  <option value="5">$1,000,000</option>
                  <option value="6">$1,500,000</option>
                  <option value="7">$2,000,000</option>
                </select>
              </td>
            </tr>
            <!-- Responsabilidad Civil muerte -->
            <tr>
              <td>
                <input 
                  type="checkbox" 
                  [(ngModel)]="selectedCoberturas['responsabilidad_civil_muerte']" 
                />
              </td>
              <td>Responsabilidad Civil Muerte</td>
              <td>
                <select [(ngModel)]="selectedCoberturas['responsabilidad_civil_muerte_id']">
                  <option value="8">$500,000</option>
                  <option value="9">$1,000,000</option>
                  <option value="10">$1,500,000</option>
                </select>
              </td>
              <td>-</td>
              <td>-</td>
            </tr>
           <!-- Responsabilidad Civil bienes -->
           <tr>
            <td>
              <input 
                type="checkbox" 
                [(ngModel)]="selectedCoberturas['responsabilidad_civil_bienes']" 
              />
            </td>
            <td>Responsabilidad Civil Bienes</td>
            <td>
              <select [(ngModel)]="selectedCoberturas['responsabilidad_civil_bienes_id']">
                <option value="11">$1,000,000</option>
                  <option value="12">$1,500,000</option>
                  <option value="13">$2,000,000</option>
              </select>
            </td>
            <td>-</td>
            <td>-</td>
          </tr>
          <tr>
            <td>
              <input 
                type="checkbox" 
                [(ngModel)]="selectedCoberturas['danos_materiales']" 
              />
            </td>
            <td>Daños Materiales</td>
            <td>-</td>
    <td>
      <select [(ngModel)]="selectedCoberturas['danos_materiales_deductible']" (change)="updateDanosMaterialesId()">
        <option value="3">3%</option>
        <option value="5">5%</option>
        <option value="7">7%</option>
      </select>
    </td>
    <td>
      <select [(ngModel)]="selectedCoberturas['danos_materiales_opcion']" (change)="updateDanosMaterialesId()">
        <option value="true">Sí</option>
        <option value="false">No</option>
      </select>
    </td>
  </tr>
  <!-- Robo total -->
  <tr>
    <td>
      <input 
        type="checkbox" 
        [(ngModel)]="selectedCoberturas['robo_total']" 
      />
    </td>
    <td>Robo total</td>
    <td>-</td>
    <td>
      <select [(ngModel)]="selectedCoberturas['robo_total_id']">
        <option value="23">0%</option>
        <option value="24">5%</option>
        <option value="25">10%</option>
        <option value="26">15%</option>
      </select>
    </td>
    <td>-</td>
  </tr>
  <!-- Robo parcial -->
  <tr>
    <td>
      <input 
        type="checkbox" 
        [(ngModel)]="selectedCoberturas['robo_parcial']" 
      />
    </td>
    <td>Robo parcial</td>
    <td>-</td>
    <td>
      <select [(ngModel)]="selectedCoberturas['robo_parcial_id']">
        <option value="27">0%</option>
        <option value="28">5%</option>
        <option value="29">10%</option>
      </select>
    </td>
    <td>-</td>
  </tr>
  <tr>
    <td>
      <input 
        type="checkbox" 
        [(ngModel)]="selectedCoberturas['conductor']" 
      />
    </td>
    <td>Accidentes al conductor</td>
    
    <td>
      <select [(ngModel)]="selectedCoberturas['conductor_id']">
        <option value="30">$50,000</option>
        <option value="31">$100,000</option>
        <option value="32">$150,000</option>
      </select>
    </td>
    <td>-</td>
    <td>-</td>
  </tr>
  <tr>
    <td>
      <input 
        type="checkbox" 
        [(ngModel)]="selectedCoberturas['asistencia_legal']"
        (change)="onCheckboxChange('asistencia_legal', 33)"
      />
    </td>
    <td>Asistencia legal</td>
    <td>-</td>
    <td>-</td>
    <td>-</td>
  </tr>
  <tr>
    <td>
      <input 
        type="checkbox" 
        [(ngModel)]="selectedCoberturas['asistencia_vial']"
        (change)="onCheckboxChange('asistencia_vial', 34)"
      />
    </td>
    <td>Asistencia vial</td>
    <td>-</td>
    <td>-</td>
    <td>-</td>
  </tr>
  <tr>
    <td>
      <input 
        type="checkbox" 
        [(ngModel)]="selectedCoberturas['lazy_fee']"
        (change)="onCheckboxChange('lazy_fee', 35)"
      />
    </td>
    <td>Emisión ágil</td>
    <td>-</td>
    <td>-</td>
    <td>-</td>
  </tr>
      <button type="button" class="btn btn-primary" (click)="guardarCobertura()">
        Guardar
      </button>
    </tbody>
            

            
        
          
          <thead *ngIf=" aseguradora === 'ps' || aseguradora === 'qualitas' || aseguradora === 'chubb' || aseguradora ==='gnp' || aseguradora ==='ana' || aseguradora ==='hdi'  ">
            <tr>
              <th>ID</th>
              <th>Selección</th>
              <th>Nombre</th>
              <th>Suma Asegurada</th>
              <th>Deducible</th>
            </tr>
          </thead>
          
          <tbody *ngIf="aseguradora !== 'gnp' && mostrarCoberturas()" >
            <tr *ngFor="let key of getKeys(coberturasActuales)">
              <td *ngIf ='paquete'>{{ key }}</td>
              <td *ngIf ='paquete' >
                <input 
                  type="checkbox" 
                  [(ngModel)]="selectedCoberturas[key]" 
                  (change)="onCheckboxChangeQualitas(key, coberturasActuales[key])" 
                />
              </td>
              <td *ngIf ='paquete'>{{ coberturasActuales[key] }}</td>
              <td *ngIf ='paquete'>
                <input 
                  type="text" 
                  class="form-control" 
                  [disabled]="!selectedCoberturas[key]" 
                  [value]="formatVisualSumaAsegurada(selectedCoberturas[key + '_sumaAsegurada'])" 
                  (input)="onSumaAseguradaInput($event, key)" 
                />
              </td>
              <td *ngIf ='paquete'>
                <input 
                  type="text" 
                  class="form-control" 
                  [disabled]="!selectedCoberturas[key]" 
                  [(ngModel)]="selectedCoberturas[key + '_deducible']"
                  (ngModelChange)="onDeducibleChange($event, key)" 
                />
              </td>
            </tr>
            <button type="button" class="btn btn-primary" (click)="guardarCobertura()">Guardar</button>
          </tbody>


          
          <tbody *ngIf=" aseguradora ==='gnp'  && mostrarCoberturas()">
            <tr *ngFor="let key of getKeys(COBERTURAS_GNP)">
              <td *ngIf="paquete">{{ COBERTURAS_GNP[key].id }}</td>
              <td *ngIf="paquete">
                <input 
                  type="checkbox" 
                  [checked]="isCoberturaSeleccionada(key)" 
                  (change)="onCheckboxChangeGNP(key, COBERTURAS_GNP[key], $event)" 
                />
              </td>
              <td *ngIf="paquete">{{ COBERTURAS_GNP[key].nombre }}</td>
          
              <!-- Select para Suma Asegurada -->
           
              <td *ngIf="paquete">
                <select 
                  class="form-select" 
                  [(ngModel)]="sumaAsegurada[key]" 
                  (ngModelChange)="onSumaAseguradaChangeGNP($event, key)" 
                  [disabled]="!isCoberturaSeleccionada(key)">
                  <option *ngFor="let suma of COBERTURAS_GNP[key].suma_asegurada" [value]="suma">
                    {{ suma | currency}}
                  </option>
                </select>
              </td>
              <td *ngIf="paquete">
                
                  <select 
                    class="form-select" 
                    [(ngModel)]="deducibleGNP[key]"  
                    (ngModelChange)="onDeducibleChangeGNP($event, key)"
                    [disabled]="!isCoberturaSeleccionada(key)">
                    <option *ngFor="let deducible of COBERTURAS_GNP[key].opcionesDeducible" [value]="deducible">
                      {{ deducible }}
                    </option>
                  </select>
                
              </td>
            </tr>
          
            <button *ngIf=" aseguradora ==='gnp' && mostrarCoberturas()" type="button" class="btn btn-primary" (click)="guardarCobertura()">Guardar</button>
          </tbody>
          

       
          
          
          
        
          
          
          
          
        </table>
      </div>
    </div>
    



<ng-template #configModal let-modal>
  <div class="modal-header">
      <h5 *ngIf="!cobertura_seleccionada || !cobertura_seleccionada.id" class="modal-title" id="editEmpresaLabel">Agregar Cobertura</h5>
      <h5 *ngIf="cobertura_seleccionada && cobertura_seleccionada.id" class="modal-title" id="editEmpresaLabel">Editar Cobertura</h5>
      <button type="button" class="btn-close" (click)="closeBtnClick()" aria-label="Close"></button>
    </div>
  
    <div class="modal-body">
        <!-- qualitas -->
        <!-- <div class="row" > -->
        <div class="row" *ngIf="provider == 'quialitas'">
            <div class="col-sm-12">
            <label class="col-sm-4 col-form-label">Paquete</label>
            <div class="col-sm-8">
              <input [(ngModel)]="cobertura_seleccionada.paquete" type="text" class="form-control" />
            </div>
          </div> 
          <div class="col-sm-12">
            <label class="col-sm-4 col-form-label">Nombre</label>
            <div class="col-sm-8">
              <input [(ngModel)]="cobertura_seleccionada.nombre" type="text" class="form-control"  />
            </div>
          </div>
          <div class="col-sm-12">
            <label class="col-sm-4 col-form-label">Data</label>
            <div class="col-sm-8">
              <textarea [(ngModel)]="cobertura_seleccionada.data" rows="10" type="text" class="form-control" ></textarea>
            </div>
          </div>
        </div>
        
       
      <div class="row" *ngIf="aseguradora == 'tumomento'"></div>

        <div class="row">
          <div class="col-sm-12">
          <label class="col-sm-4 col-form-label">Paquete</label>
          
          <div class="col-sm-8">
            <input [(ngModel)]="cobertura_seleccionada.paquete" type="text" class="form-control" />
          </div>
        </div> 
        <div class="col-sm-12">
          <label class="col-sm-4 col-form-label">Nombre</label>
          <div class="col-sm-8">
            <input [(ngModel)]="cobertura_seleccionada.nombre" type="text" class="form-control"  />
          </div>
        </div>
        <div class="col-sm-12">
          <label class="col-sm-4 col-form-label">Data</label>
          <div class="col-sm-8">
            <textarea [(ngModel)]="cobertura_seleccionada.data" rows="10" type="text" class="form-control" ></textarea>
          </div>
        </div>
      </div>

   


        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            (click)="closeBtnClick()"
          >
            Cancelar
          </button>
  
          <button
            type="button"
            class="btn btn-primary"
            (click)="guardarCobertura()"
          >
            Guardar
          </button>
        </div>
    </div>
</ng-template>




<ng-template #empresaModal let-modal>
  <div class="modal-header">
      <h5 class="modal-title" id="editEmpresaLabel">Agregar Empresa</h5>
      <button type="button" class="btn-close" (click)="closeBtnClick()" aria-label="Close"></button>
    </div>
  
    <div class="modal-body">    
        <div class="row">
          <div class="col-sm-12">
            <label class="col-sm-4 col-form-label">Nombre</label>
            <div class="col-sm-8">
              <input [(ngModel)]="nueva_empresa" type="text" class="form-control" />
            </div>
          </div> 
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            (click)="closeBtnClick()"
          >
            Cancelar
          </button>
  
          <button
            type="button"
            class="btn btn-primary"
            (click)="guardarEmpresa()"
          >
            Guardar
          </button>
        </div>
    </div>
</ng-template>
